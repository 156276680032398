import angular from 'angular';

import SceneCollectionService from 'common/scenecollection.service';
import socialMediaService from 'common/social-media.service.js';
import BulkApplyService from 'common/bulk-apply.service';

import bulkSelect from 'components/bulk-select';
import emptyInfo from 'components/empty-info';
import medialabel from 'components/medialabel';

import component from './social-media.component';

export default angular.module('manager.common.library.social-media', [
	bulkSelect,
	emptyInfo,
	medialabel,
	socialMediaService,
	SceneCollectionService,
	BulkApplyService
])
	.directive('librarySocialMedia', component)
	.name;
