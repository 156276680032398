import angular from 'angular';
import angularStrap from 'angular-strap';
import ClientService from 'common/client.service';

import component from './about.component';

export default angular.module('about', [
	angularStrap,
	ClientService
]).component('about', component);
