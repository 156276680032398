import templateUrl from './player-preview.component.html';

export default function playerPreviewComponent($log, $timeout, ApiService, PlayerFactory, PlayerUtil, SceneService) {
	'ngInject';
	return {
		restrict: 'E',
		scope: {
			scene: '='
		},
		bindToController: {},
		controller: () => {},
		controllerAs: 'ctrl',
		templateUrl: templateUrl,
		link: function(scope, elem) {
			// Setup stage
			let stageWrapper = angular.element(elem[0].querySelector('#stageWrapper'));
			PlayerUtil.setOrientationClass(scope.scene.orientation, stageWrapper);
			let stage = angular.element(elem[0].querySelector('#stage'));

			let currentlyPlayingId;
			let playing;

			// Monitor for changes to currentlyPlayingId on the scene
			const unsubscribe = SceneService.subscribeToId(scope.scene.id, scene => {
				// On change
				if (currentlyPlayingId !== scene.currentlyPlayingId) {
					currentlyPlayingId = scene.currentlyPlayingId;

					// Fetch playlistItem
					ApiService.call('playlistitem/getPlaylistItem', { id: currentlyPlayingId })
						.then(playlistItem => {
							if (typeof playlistItem.itemData === 'string') {
								try {
									// Parse
									playlistItem.itemData = JSON.parse(playlistItem.itemData);

									// Do the shuffle
									const itemData = playlistItem.itemData;
									delete playlistItem.itemData;
									Object.assign(playlistItem, itemData);
								}
								catch (e) {
									$log.error('[PlayerPreview] Could not load parse itemData', e);
									return;
								}

								// Build player
								const player = PlayerFactory.create(playlistItem, scope.scene.orientation, scope);
								if (player) {
									play(player);
								}
							}
						})
						.catch(err => {
							$log.error('[PlayerPreview] Could not load playlistItem', err);
						});
				}
			});

			scope.$on('$destroy', unsubscribe);

			function play(player) {
				// Play ended for previous
				if (playing) {
					playing.scope.$destroy();
					playing = null;
				}

				// Play next. Get data if not provided (is on first load)
				playing = player;
				stage.html('');
				stage.append(playing.elem);
			}

			// Emitted when a player is ready to play
			scope.$on('preloadComplete', function(event, id) {
				$log.log(`[PlayerPreview] preloadComplete of item ${id} for scene id ${scope.scene.id}`);
				playing.api.play();
			});

			scope.$on('playingEnded', function(event, id) {
				$log.log(`[PlayerPreview] playingEnded of item ${id} for scene id ${scope.scene.id}`);
			});

			// Emitted when a player encountered an error
			scope.$on('playerError', function(event, id, error) {
				$log.error(`[PlayerPreview] playerError of item ${id} for scene id ${scope.scene.id}. Error: ${error.message}`, error);
			});
		}
	};
}
