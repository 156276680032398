import angular from 'angular';

import versionHelpers from '../../../shared/version-helpers';

export default angular.module('filters.jumbotron-version', [])
	.filter('jumbotronVersion', function() {
		// Converts a version object to something readable
		return versionHelpers.getStringified;
	})
	.filter('jumbotronVersionJson', function($log) {
		'ngInject';

		return function(json) {
			let parsed;

			if (json) {
				try {
					parsed = JSON.parse(json);
				}
				catch (e) {
					$log.warn(`Could not parse input for jumbotronVersionJson: ${json}`, e);
				}
			}

			// Converts a version object to something readable
			return versionHelpers.getStringified(parsed);
		};
	})
	.name;
