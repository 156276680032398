export default class AuthService {
	constructor($log, $state, $q, authConfig, ApiService, PrimusService, LocalStorageService, RavenService, StateService) {
		'ngInject';
		this._$log = $log;
		this._$state = $state;
		this._$q = $q;
		this._authConfig = authConfig;
		this._ApiService = ApiService;
		this._LocalStorageService = LocalStorageService;
		this._RavenService = RavenService;
		this._StateService = StateService;
		this._stateSubscribed = false;

		// Setup subscription to auth change events
		this._ApiService.on('auth/isAuthenticated', (...args) => this._authChange('isAuthenticated', ...args));
		this._ApiService.on('auth/login', (...args) => this._authChange('login', ...args));
		this._ApiService.on('auth/logout', (...args) => this._authChange('logout', ...args));

		// Check auth state when connection opens
		PrimusService.on('open', () => this._ApiService.call('auth/isAuthenticated'));

		// Check auth state on load
		this._ApiService.call('auth/isAuthenticated');
	}

	_authChange(event, err, authData) {
		if (err) {
			this._$log.error(`AuthService: Event '${event}' error`, err);
			this._authClear();
			return;
		}

		this._$log.info(`AuthService: Event '${event}'`, authData);

		// Login or authenticated
		if (event === 'login' || (event === 'isAuthenticated' && authData.authenticated)) {
			let delay = 0;
			if (event === 'login') {
				delay = 1000;
			}
			// Patch auth data
			const currentAuthData = this._LocalStorageService.get('authdata');
			this._LocalStorageService.set('authdata', Object.assign(currentAuthData || {}, authData));

			// Ensure async operations are completed before next steps
			this._$q.resolve()
				.then(() => this._$q((resolve) => setTimeout(resolve, delay)))
				.then(() => {
					// Set user context for Raven
					this._RavenService.setUserContext(currentAuthData);
					// Subscribe to full state
					if (!this._stateSubscribed) {
						this._StateService._authenticatedSubscribeToFullState();
						this._stateSubscribed = true;
					}

					// Redirect to authed state if on not-authed state
					if (this._$state.current.name === this._authConfig.notAuthenticatedState) {
						this._$state.go(this._authConfig.authenticatedInitialState);
					}
				});
		}
		// Otherwise
		else {
			this._authClear();
		}
	}

	_authClear() {
		this._$log.info(`AuthService: Clearing auth`);

		// Clear auth data
		this._LocalStorageService.set('authdata', null);

		// Clear user context for Raven
		this._RavenService.setUserContext();

		// Redirect to not-authenticated state, if not there
		if (this._$state.current.name !== this._authConfig.notAuthenticatedState) {
			this._$state.go(this._authConfig.notAuthenticatedState, { reload: true });
		}
	}

	login(username, password) {
		return this._ApiService.call('auth/login', { username, password });
	}

	logout() {
		return this._ApiService.call('auth/logout');
	}

	get isAuthenticated() {
		const authData = this._LocalStorageService.get('authdata');
		return (authData && authData.authenticated) || false;
	}
}
