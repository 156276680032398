export function pageTitleRun($window, $transitions) {
	'ngInject';

	// Update page title dynamically
	$transitions.onSuccess({}, transition => {
		const toState = transition.to();

		// Build page title
		let pageTitle = 'JumboTRON';
		if (toState.history && toState.history.title) {
			pageTitle += ' ' + toState.history.title;

			// Append scene name
			if (toState.name === 'manager.scene') {
				pageTitle += ' - ' + transition.params('to').id;
			}
		}

		// Set page title
		$window.document.title = pageTitle;
	});
}
