import angular from 'angular';

import LocalStorageService from './local-storage.service';

class UserService {
	constructor(LocalStorageService) {
		'ngInject';

		this._LocalStorageService = LocalStorageService;
	}

	getUser() {
		return this._LocalStorageService.get('authdata');
	}
}

export default angular.module('user.service', [
	LocalStorageService
])
	.service('UserService', UserService).name;
