export default class AssetUploadService {
	constructor($q, $rootScope, Upload, ApiService) {
		'ngInject';

		this._$q = $q;
		this._$rootScope = $rootScope;
		this._Upload = Upload;
		this._ApiService = ApiService;

		this._filenameRegex = /^(.+)_(l|p)\.(.+)$/i;

		this._uploadQueue = [];
		this._uploading = false;
	}

	_emitUploadStats() {
		// Calculate upload stats
		const stats = {
			filesRemaining: this._uploading ? 1 + this._uploadQueue.length : 0,
			bytesRemaining: 0
		};

		stats.bytesRemaining += this._uploading ? this._uploading.file.bytesRemaining : 0;
		stats.bytesRemaining += this._uploadQueue.reduce((accumulator, obj) => accumulator + obj.file.bytesRemaining, 0);

		// And emit
		this._$rootScope.$emit('asset-upload-service:stats', stats);
	}

	_parseFilename(filename) {
		const match = filename.match(this._filenameRegex);
		if (!match) {
			return this._$q.reject(new Error(`Could not parse filename: '${filename}'`));
		}

		return {
			name: match[1],
			orientation: match[2],
			extension: match[3]
		};
	}

	_processUploadQueue() {
		// Get element to process (if any) and emit stats
		this._uploading = this._uploadQueue.shift();
		this._emitUploadStats();
		if (!this._uploading) {
			return;
		}
		const { file, resolve, reject } = this._uploading;

		this._$q.when()
			.then(() => {
				// Parse and validate file name
				return this._parseFilename(file.name);
			})
			.then(({ name, orientation }) => {
				// Create asset / request upload parameters
				return this._ApiService.call('asset/createAsset', {
					filename: file.name,
					name,
					orientation,
					bytes: file.size
				});
			}).then(uploadParameters => {
				// Just add file and mime type
				uploadParameters.file = file;
				uploadParameters.fields['Content-Type'] = file.type && file.type !== '' ? file.type : 'application/octet-stream';

				// Start the upload
				return this._Upload.upload(uploadParameters)
					.progress(event => {
						// Emit stats
						file.bytesRemaining = event.total - event.loaded;
						this._emitUploadStats();
					});
			})
			.then(() => {
				resolve();
			})
			.catch(err => {
				reject(err);
			})
			.finally(() => {
				this._processUploadQueue();
			});
	}

	uploadFile(file) {
		return this._$q.when()
			.then(() => {
				// Parse and validate file name
				this._parseFilename(file.name);
			})
			.then(() => {
				return this._$q((resolve, reject) => {
					// Queue upload
					this._uploadQueue.push({
						file,
						resolve,
						reject
					});

					// Helper for stats
					file.bytesRemaining = file.size;

					// Emit stats
					this._emitUploadStats();

					// Trigger processing of queue if not active
					if (!this._uploading) {
						this._processUploadQueue();
					}
				});
			});
	}
}
