export function routerConfig($locationProvider, $urlRouterProvider) {
	'ngInject';

	$locationProvider.html5Mode({
		enabled: true,
		requireBase: true
	});

	$urlRouterProvider.otherwise('/library');
}
