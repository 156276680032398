import templateUrl from './service-announcement-edit.component.html';

class ServiceAnnouncementEditController {
	constructor($log, ApiService, HistoryService, ServiceAnnouncementsService) {
		'ngInject';

		this._$log = $log;
		this._ApiService = ApiService;
		this._HistoryService = HistoryService;
		this._ServiceAnnouncementsService = ServiceAnnouncementsService;
	}

	$onInit() {
		this.edit = angular.isDefined(this.id);

		this.org = {
			name: '',
			headline: '',
			body: ''
		};
		this.item = angular.copy(this.org);

		if (this.edit) {
			this._ServiceAnnouncementsService.get(this.id)
				.then(serviceAnnouncement => {
					this.org = serviceAnnouncement;
					this.item = angular.copy(this.org);
				});
		}
	}

	submit(valid) {
		if (!valid) {
			return;
		}

		if (this.edit) {
			this.save();
		}
		else {
			this.add();
		}
	}

	add() {
		this._ApiService.call('serviceannouncement/createServiceAnnouncement', this.item)
			.catch(error => {
				this._$log.log('ServiceAnnouncementEdit [add] failed. Service Announcement was %o and error was ' + error.code, this.item);
			})
			.then(() => {
				this._HistoryService.goBackOrTo('manager.library');
			});
	}

	save() {
		angular.extend(this.org, this.item);

		this._ApiService.call('serviceannouncement/updateServiceAnnouncement', this.org)
			.catch(error => {
				this._$log.log('ServiceAnnouncementEdit [save] failed. Service Announcement was %o and error was ' + error.code, this.item);
			})
			.then(() => {
				this._HistoryService.goBackOrTo('manager.library');
			});
	}

	remove() {
		if (!this.org.sceneCollections) {
			this._ApiService.call('serviceannouncement/deleteServiceAnnouncement', this.org)
				.catch(error => {
					this._$log.warn('ServiceAnnouncementEdit [delete] failed. Service Announcement was %o and error was ' + error, this.org);
				})
				.then(() => {
					this._HistoryService.goBackOrTo('manager.library');
				});
		}
	}
}

export default {
	bindings: {
		id: '<?'
	},
	controller: ServiceAnnouncementEditController,
	templateUrl
};
