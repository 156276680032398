import { v4 as uuidv4 } from 'uuid';

export default class ApiService {
	constructor($log, $q, PrimusService) {
		'ngInject';

		this._$log = $log;
		this._$q = $q;
		this._PrimusService = PrimusService;

		// Listeners for the different events
		this._responseListeners = {};

		// Listen for event responses
		this._PrimusService.on('data', this._primusOnData.bind(this));

		// Server might've rebooted, resubscribe on reconnect.
		this._PrimusService.on('reconnected', this._primusOnReconnect.bind(this));

		// Debugging help
		if (process.env.NODE_ENV === 'development') {
			window.ApiService = this;
		}
	}

	_primusOnData(data) {
		// If our data doesn't have an event, it's not sent by us, it's sent by an abstraction layer.
		if (!data.event) {
			return;
		}

		// Check for errors!
		if (data.event.toLowerCase() === 'error') {
			this._$log.error('[API] Got general error from provider:', data);
		}

		// Normalize the name to direct to relevant listeners.
		const normalizedEvent = data.event.replace('Response', '').replace('Error', '');
		const listener = this._responseListeners[normalizedEvent];
		if (listener) {
			let error;
			let response;

			if (data.event.endsWith('Error')) {
				error = data.errorMessage || data.error || false;
				this._$log.warn('[API] Got error from provider:', data);
			}

			if (data.event.endsWith('Response')) {
				response = data.data;
			}

			if (data.event.endsWith('Changed')) {
				response = data.instance;
			}

			// Notify subscribers
			listener.subscriptions.forEach(listener => listener(error, response));

			// Notify and clear one-time response listeners
			listener.once = listener.once.filter(listener => {
				if (listener.uuid === data.uuid) {
					listener.resolver(error, response);
					return false;
				}

				return true;
			});
		}
	}

	_primusOnReconnect() {
		// Iterate over the model listeners we know.
		Object.keys(this._responseListeners).forEach(key => {
			if (key.endsWith('/subscribe')) {
				this.call(key, this._responseListeners[key].parameters);
			}
		});
	}

	// Ensures an event is present in our _responseListeners object, just to simplify things.
	_getResponseListenerContainer(event) {
		if (!this._responseListeners[event]) {
			this._responseListeners[event] = {
				subscriptions: [],
				once: [],
				parameters: {}
			};
		}

		return this._responseListeners[event];
	}

	// Adds a subscriber that listens for specific event responses.
	on(event, listener) {
		this._getResponseListenerContainer(event).subscriptions.push(listener);
	}

	// Helper function that calls the api with the correct wrapper.
	call(event, parameters = {}) {
		if (!event) {
			this._$log.error('[API] Call did not get event!');
			return this._$q.reject('No event provided!');
		}

		return this._$q((resolve, reject) => {
			// Build request
			const request = {
				event,
				uuid: uuidv4(),
				data: parameters
			};

			// Build listener for the response
			const onceSubscriber = {
				uuid: request.uuid,
				resolver: (err, data) => {
					if (err) {
						return void reject(err);
					}

					resolve(data);
				}
			};

			// Setup listener for the response
			let responseListener = this._getResponseListenerContainer(event);
			responseListener.once.push(onceSubscriber);
			responseListener.parameters = parameters;

			// Send event
			this._PrimusService.write(request);
		});
	}

	// Relevant internally and for model relations.
	subscribeToModel(model, filter, listener) {
		this.on(`${model.toLowerCase()}Changed`, listener);

		// NB: Return status of the setup of the subscription, not an unsubscribe function!
		return this.call(`${model.toLowerCase()}/subscribe`, { filter })
			.then(response => {
				this._$log.info(`Successfully subscribed to ${model}`, response);
				return response;
			})
			.catch(err => {
				this._$log.error(`[State] Could not subscribe to ${model}`);
				return err;
			});
	}
}
