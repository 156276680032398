import templateUrl from './scene-status.component.html';

class SceneStatusController {
	constructor($scope, SceneService) {
		'ngInject';

		this._$scope = $scope;
		this._SceneService = SceneService;
	}

	$onInit() {
		this.unsubscribe = this._SceneService.subscribeToId(this.id, scene => {
			this.scene = scene;
		});

		this._$scope.$on('$destroy', this.unsubscribe);
	}
}

export default {
	bindings: {
		id: '<'
	},
	controller: SceneStatusController,
	templateUrl
};
