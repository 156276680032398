export function requiresAuthHook($log, $transitions, authConfig, AuthService) {
	'ngInject';

	// Check if requiresAuth is truthy
	const requiresAuthCriteria = {
		to: state => state.data && state.data.requiresAuth
	};

	// Check that user is authenticated, and redirect to login otherwise
	const redirectToLogin = transition => {
		const $state = transition.router.stateService;

		if (!AuthService.isAuthenticated) {
			$log.warn(`Auth required for transitioning to state: '${transition.to().name}'. Redirecting to login.`);
			return $state.target(authConfig.notAuthenticatedState, undefined, { location: false });
		}

		return null;
	};

	$transitions.onBefore(requiresAuthCriteria, redirectToLogin, { priority: 10 });
}
