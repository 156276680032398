import angular from 'angular';
import api, { AbstractCollectionService } from 'components/api';

class SceneService extends AbstractCollectionService {
	constructor($q, ApiService, StateService) {
		'ngInject';

		super($q, ApiService, StateService, 'Scene', 'scene');
	}

	getByIdOrName(idOrName) {
		if (isNaN(idOrName)) {
			return this.getByName(idOrName);
		}

		return this.get(idOrName);
	}

	getByName(name) {
		const item = (this.localState[this.collectionStateName] || []).find(item => item.name === name);

		if (item) {
			return item;
		}

		return this.ApiService.call(`${this.collectionEndpoint}/get${this.collectionName}`, { name });
	}

	setCurrentlyPlaying(id, playlistItemId) {
		return this.update({
			id,
			currentlyPlayingId: playlistItemId
		});
	}
}

export default angular.module('scene.service', [
	api
])
	.service('SceneService', SceneService).name;
