import angular from 'angular';
import LocalStorageModule from 'angular-local-storage';

class LocalStorageService {
	constructor(localStorageService) {
		'ngInject';

		this._localStorageService = localStorageService;
	}

	get(key) {
		return this._localStorageService.get(key);
	}

	set(key, value) {
		return this._localStorageService.set(key, value);
	}

	remove(key) {
		return this._localStorageService.remove(key);
	}
}

export default angular.module('local-storage.service', [LocalStorageModule])
	.config(function(localStorageServiceProvider) {
		'ngInject';

		localStorageServiceProvider.setPrefix('roskilde.jumbotron');
	})
	.service('LocalStorageService', LocalStorageService)
	.name;
