import templateUrl from './service-announcement-player.component.html';

export default function serviceAnnouncementPlayerComponent($timeout, PlayerUtil) {
	'ngInject';
	return {
		restrict: 'E',
		scope: false, // TODO: Refactor
		bindToController: {},
		controller: () => {},
		controllerAs: '$ctrl',
		templateUrl,
		link: function(scope, $elem) {
			scope.getAsset = function(name) {
				return PlayerUtil.getAssetWithOrientation(scope.$parent.scene.orientation, name);
			};

			$elem.css('background-image', `url('${scope.getAsset('bg_service_announcement.jpg')}'`);

			let timer = null;
			scope.data.played = false;

			scope.api.play = function() {
				scope.data.played = false;
				timer = $timeout(function() {
					scope.data.played = true;
					scope.$emit('playingEnded', scope.data.id);
				}, scope.data.duration);
			};

			scope.api.hasPlayed = function() {
				return scope.data.played;
			};

			scope.$on('$destroy', function() {
				$timeout.cancel(timer);
				$elem.remove();
			});

			// No data to preload, so we just trigger it after initialization (after this has been constructed, hence the timeout)
			$timeout(() => {
				scope.$emit('preloadComplete', scope.data.id);
			});
		}
	};
}
