import angular from 'angular';
import { v4 as uuid } from 'uuid';

import api from 'components/api';

class ClientService {
	constructor(PrimusService, ApiService, LocalStorageService) {
		'ngInject';

		this._VERSION = VERSION;
		this._ABOUT = ABOUT;
		this._ApiService = ApiService;
		this._LocalStorageService = LocalStorageService;

		// Check version when connection opens
		PrimusService.on('open', this._checkForNewerVersion.bind(this));

		// Force check on app start
		this._checkForNewerVersion();
	}

	_checkForNewerVersion() {
		this._ApiService.call('server/getVersion')
			.then(version => {
				// Something has changed, update to latest version.
				if (JSON.stringify(version) !== JSON.stringify(this._VERSION)) {
					location.reload(true);
				}
			});
	}

	get version() {
		return Object.assign({}, this._VERSION);
	}

	get about() {
		return this._ABOUT;
	}

	// Get (or generate) client id
	get id() {
		let id = this._LocalStorageService.get('client-id');

		if (!id) {
			this._LocalStorageService.set('client-id', uuid());
			id = this._LocalStorageService.get('client-id');
		}

		return id;
	}
}

export default angular.module('client.service', [
	api
])
	.service('ClientService', ClientService).name;
