import angular from 'angular';
import uirouter from '@uirouter/angularjs';
import angularStrap from 'angular-strap';
import toaster from 'angularjs-toaster';
import 'angularjs-toaster/toaster.css';

import alerts from '../alerts';

import ClientService from 'common/client.service';
import SceneService from 'common/scene.service';

import component from './manager.component';

export default angular.module('manager', [
	uirouter,
	angularStrap,
	toaster,
	ClientService,
	SceneService,
	alerts
])
	.component('manager', component);
