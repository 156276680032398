class ScenePreLoaderController {
	constructor($compile, $scope) {
		'ngInject';

		this._$compile = $compile;
		this._$scope = $scope;
	}

	$onInit() {
		// Create scope and attach scene object
		const newScope = this._$scope.$new(true);
		newScope.scene = this.scene;

		// Build element
		const element = `<${this.build} scene="scene"></${this.build}>`;
		const item = this._$compile(element)(newScope);

		// Append element
		let thisElement = angular.element(document.querySelector('scene-pre-loader'));
		thisElement.append(item);
	}
}

export default {
	bindings: {
		scene: '<',
		build: '@'
	},
	controller: ScenePreLoaderController
};
