
export default class FeedbackService {
	constructor($window, ApiService, ClientService) {
		'ngInject';

		this._$window = $window;
		this._ApiService = ApiService;
		this._ClientService = ClientService;
	}

	collectClientInformation() {
		const clientInformation = {
			userAgent: this._$window.navigator.userAgent,
			id: this._ClientService.id,
			version: this._ClientService.version
		};

		return clientInformation;
	}

	sendFeedback(name, email, subject, message) {
		const data = {
			sender: {
				name,
				email
			},
			subject,
			message,
			clientInformation: this.collectClientInformation()
		};

		return this._ApiService.call('feedback/sendFeedback', data);
	}
}
