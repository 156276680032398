import angular from 'angular';
import ngMessages from 'angular-messages';
import uirouter from '@uirouter/angularjs';

import RavenService from 'common/raven.service';
import api from 'components/api';

import authConfig from './auth.config';
import AuthService from './auth.service';
import { requiresAuthHook } from './requiresAuth.hook';

export default angular.module('auth-utils', [
	RavenService,
	ngMessages,
	uirouter,
	api
])
	.provider('authConfig', authConfig)
	.service('AuthService', AuthService)
	.config(authConfigProvider => {
		'ngInject';

		authConfigProvider.configure({
			notAuthenticatedState: 'login',
			authenticatedInitialState: 'manager.library'
		});
	})
	.run(requiresAuthHook)
	.name;
