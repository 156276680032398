export function logDecorator($provide) {
	'ngInject';

	$provide.decorator('$log', function $logDecorator($delegate, AlertsService) {
		'ngInject';

		if (process.env.NODE_ENV !== 'development') {
			return $delegate;
		}

		const originalError = $delegate.error;
		$delegate.error = function alertError(message, data) {
			AlertsService.add('danger', message, data);
			originalError.apply($delegate, arguments);
		};

		const originalWarn = $delegate.warn;
		$delegate.warn = function alertWarn(message, data) {
			AlertsService.add('warning', message, data);
			originalWarn.apply($delegate, arguments);
		};

		return $delegate;
	});
}
