class BackButton {
	constructor($log, HistoryService) {
		'ngInject';

		this.HistoryService = HistoryService;

		// Get and store history data
		this.previousStateHistory = HistoryService.getPreviousStateDetails();
		this.defaultStateHistory = HistoryService.getStateDetails(this.defaultStateName);

		// Check if details of the default state can be read
		if (this.defaultStateName && !this.defaultStateHistory) {
			$log.warn('BackButton has no default state.');
		}

		// Set title
		let title = 'Back';
		if (this.previousStateHistory) {
			title += ' to ' + this.previousStateHistory.title;
		}
		else if (this.defaultStateHistory) {
			title += ' to ' + this.defaultStateHistory.title;
		}
		this.title = title;
	}

	goBack() {
		this.HistoryService.goBackOrTo(this.defaultStateName);
	}
}

export default BackButton;
