import angular from 'angular';

import playerUtil from 'common/player-util.service';

import component from './service-announcement-player.component';

export default angular.module('service-announcement-player', [
	playerUtil
])
	.directive('serviceAnnouncementPlayer', component)
	.name;
