import angular from 'angular';

import ClientService from 'common/client.service';
import SceneService from 'common/scene.service';

import component from './scenes.component';

export default angular.module('scenes', [
	ClientService,
	SceneService
])
	.component('scenes', component);
