import angular from 'angular';

import playQueueService from 'common/play-queue.service.js';
import playerFactory from 'components/player-factory';

import component from './player-preview.component';

export default angular.module('player-preview', [
	playQueueService,
	playerFactory
])
	.directive('playerPreview', component)
	.name;
