import templateUrl from './rosprover.template.html';

class RosproverController {
	constructor($log, $interval, $timeout, SapiService) {
		'ngInject';
		this._$log = $log;
		this._$interval = $interval;
		this._$timeout = $timeout;
		this._SapiService = SapiService;
		this.posts = [];
		this.noMoreItems = false; // Flag to indicate no more items available
		this.ITEMS_THRESHOLD = 3;
		this.FETCH_INTERVAL = 60000; // 60 seconds
		this.RETRY_INTERVAL = 10000; // 10 seconds
	}

	$onInit() {
		this.fetchPosts();
		this.fetchInterval = this._$interval(() => this.fetchPosts(), this.FETCH_INTERVAL);
	}

	$onDestroy() {
		this._$interval.cancel(this.fetchInterval);
	}

	fetchPosts() {
		this._$log.info('Fetching posts for moderation');
		this._SapiService.getPostsForModeration()
			.then((data) => {
				if (data.length > 0) {
					this.posts = this.posts.concat(data);
					this.noMoreItems = false;
				}
				else {
					this.noMoreItems = true;
					this.retryFetchPosts();
				}
			})
			.catch((error) => {
				this._$log.error('Error fetching moderation posts:', error);
				this.retryFetchPosts();
			});
	}

	retryFetchPosts() {
		this._$timeout(() => this.fetchPosts(), this.RETRY_INTERVAL);
	}

	checkAndFetchPosts() {
		if (this.posts.length < this.ITEMS_THRESHOLD && !this.noMoreItems) {
			this.fetchPosts();
		}
	}

	getPostsForList() {
		return this.posts;
	}

	updatePostApproval(postId, approvalState) {
		this._SapiService.updatePostApprovalState(postId, approvalState)
			.then(() => {
				let index = this.posts.findIndex(item => item.id === postId);
				if (index !== -1) {
					this.posts.splice(index, 1);
					this.checkAndFetchPosts();
				}
			})
			.catch(() => {
				this._$log.error('Error updating post approval state');
			});
	}
}

export default {
	controller: RosproverController,
	templateUrl
};
