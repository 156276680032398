import angular from 'angular';

import scenes from 'components/scenes';

import component from './settings.component';

export default angular.module('settings', [
	scenes.name
])
	.component('settings', component);
