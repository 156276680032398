import controller from './back-button.controller';
import templateUrl from './back-button.component.html';

export default function backButtonComponent() {
	'ngInject';
	return {
		restrict: 'E',
		scope: {},
		bindToController: {
			defaultStateName: '@defaultState'
		},
		controller,
		controllerAs: '$ctrl',
		templateUrl,
		link: function($scope, $element, $attrs, $ctrl) {
			$element.attr('type', 'button');

			if (!$ctrl.previousStateHistory && !$ctrl.defaultStateHistory) {
				// If there is no previous state and no default, then the button is not displayed
				$element.css('display', 'none');
			}
		}
	};
}
