import 'assets';

import angular from 'angular';
import uirouter from '@uirouter/angularjs';
import angularLogglyLogger from 'angular-loggly-logger';
import angularTimeago from 'vendorfix/angular-timeago';
import ravenAngular from 'raven-js/plugins/angular';
// Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';

import constants from './constants';

import api from 'components/api';
import authentication from 'components/authentication';

import history from 'components/history';

import routes from './routes.module';

// Apply global styles after all module loading
import 'style.less';

// App config hooks
import { logglyConfig } from './app/loggly.config';
import { angularTimeagoConfig } from './app/angular-timeago.config';
import { routerConfig } from './app/router.config';

// App run hooks
import { routerDebugRun } from './app/router-debug.run';
import { routerRun } from './app/router.run';
import { pageTitleRun } from './app/page-title.run';
import { angularXeditableRun } from './app/angular-xeditable.run';

export default angular.module('app', [
	ravenAngular.moduleName,
	uirouter,
	angularLogglyLogger,
	angularTimeago,
	constants.name,
	api,
	authentication,
	history,
	routes.name
])
	.config(logglyConfig)
	.config(angularTimeagoConfig)
	.config(routerConfig)
	.run(routerDebugRun)
	.run(routerRun)
	.run(pageTitleRun)
	.run(angularXeditableRun);
