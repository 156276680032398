export default function playerComponent($log, $interval, $state, ClientService, PlayerFactory, PlayQueueService, PlayerUtil, PrimusService, SceneService) {
	'ngInject';
	return {
		restrict: 'E',
		scope: {
			scene: '='
		},
		bindToController: {},
		controller: () => {},
		controllerAs: 'ctrl',
		template: '<div id="stageWrapper"><div id="stage"></div></div>',
		link: function(scope, elem) {
			// If this Client isn't configured for this scene, show a big fat warning and don't do anything further!
			if (ClientService.id !== scope.scene.clientId) {
				let state = angular.element(elem[0].querySelector('#stage'));
				state.html('<h1>This browser is not setup for <br><b>' + scope.scene.name + '</b></h1>');
				state.addClass('text-center');
				return;
			}

			if ('serviceWorker' in navigator) {
				window.addEventListener('beforeunload', () => {
					// Send a message to the service worker to signal a page refresh
					if (navigator.serviceWorker && navigator.serviceWorker.controller) {
						navigator.serviceWorker.controller.postMessage({
							type: 'PAGE_REFRESH'
						});
					}
				});
				navigator.serviceWorker.register('/service-worker.js')
					.then(function(registration) {
						$log.info('Service Worker registered with scope:', registration.scope);

						// Check for updates in the background
						registration.update();

						// Listen for updates and take immediate control once updated:
						registration.addEventListener('updatefound', () => {
							const newWorker = registration.installing;
							newWorker.addEventListener('statechange', () => {
								if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
									$log.info('New service worker installed and ready to take over.');

									// Post message to new service worker to skip waiting
									newWorker.postMessage({ type: 'SKIP_WAITING' });

									// Optional: Automatically reload the page to activate the new service worker
									window.location.reload();
								}
							});
						});
					})
					.catch(function(error) {
						$log.info('Service Worker registration failed:', error);
					});
			}

			// Setup stage
			let stageWrapper = angular.element(elem[0].querySelector('#stageWrapper'));
			PlayerUtil.setOrientationClass(scope.scene.orientation, stageWrapper);
			let stage = angular.element(elem[0].querySelector('#stage'));

			// NB: Epic hack ahead
			// When connection is lost and regained, subscriptions aren't setup with the filters,
			// thus list stops synchronizing. Reload of state must do the trick for now.
			PrimusService.on('reconnected', () => {
				$log.warn(`[Player] Primus reconnected: Reloading for scene id ${scope.scene.id}:`, { scene: scope.scene });
				clearPlayQueue();
				getPlayQueueForPlayback();
			});

			/// Player logic

			let playing = null;
			const playQueue = {};

			// NB: playQueue has circular structure, as the child scopes reference their parent.
			function getPlayQueueForLogging() {
				const logQueue = {};
				for (const [key, value] of Object.entries(playQueue)) {
					logQueue[key] = {
						id: value.id,
						ready: !!value.ready,
						data: value.scope.data
					};
				}

				return logQueue;
			}

			function getPlayQueueForPlayback() {
				PlayQueueService.get(scope.scene.id)
					.then(playlistItems => {
						for (const playlistItem of playlistItems) {
							handlePlayQueueChange(null, playlistItem);
						}
					})
					.catch(err => {
						$log.error(`[Player] Could not get current playQueue for scene id ${scope.scene.id}`, err);
					});
			}

			function cleanupPlayer(playerObj) {
				playerObj.scope.$destroy();
				if (playing && playing.id === playerObj.id) {
					playing = null;
				}
			}

			function clearPlayQueue() {
				for (let key in playQueue) {
					if (playQueue.hasOwnProperty(key)) {
						delete playQueue[key];
					}
				}
			}

			function playNext() {
				if (playing) {
					// Stall check
					if (playing.api.hasPlayed()) {
						$log.warn(`[Player] playNext: playing hasPlayed, forcing cleanup for scene id ${scope.scene.id}:`, playing);
						cleanupPlayer(playing);
					}
					else {
						return;
					}
				}

				// Find (sorted) the next ready item to play
				const playlistItemIds = Object.keys(playQueue).map(id => parseInt(id)).sort((a, b) => a - b);
				let idToPlay = null;
				for (const playlistItemId of playlistItemIds) {
					if (playQueue[playlistItemId].ready) {
						idToPlay = playlistItemId;
						break;
					}
					else {
						$log.warn(`[Player] playNext: Skipping ${playlistItemId}, NOT ready!`);
					}
				}

				if (!idToPlay) {
					$log.warn('[Player] playNext: Nothing ready to be played', getPlayQueueForLogging());
					return;
				}

				// Setup for playing!
				playing = playQueue[idToPlay];
				delete playQueue[idToPlay];
				stage.html('');
				stage.append(playing.elem);

				$log.log(`[Player] playNext: playing item with id ${playing.id} of type ${playing.scope.data.type}`, getPlayQueueForLogging());

				playing.api.play();
				PlayQueueService.playingStarted(idToPlay, 'Playback started');
				SceneService.setCurrentlyPlaying(scope.scene.id, idToPlay);
			}

			let playNextInterval = $interval(playNext, 1000);

			// Emitted when a player is ready to play
			scope.$on('preloadComplete', function(event, id) {
				$log.log(`[Player] preloadComplete of item ${id} for scene id ${scope.scene.id}`);
				if (playQueue[id]) {
					// Q: How can we get a preloadComplete if the item is not on the queue?
					// A1: Item already destroyed, but listeners not removed
					// A2: video can get 'canplaythrough' multiple times for the same video
					playQueue[id].ready = true;
				}
			});

			// Emitted when a player has successfully played
			scope.$on('playingEnded', function(event, id) {
				if (id === playing.id) {
					$log.log(`[Player] playingEnded of item ${id} for scene id ${scope.scene.id}`);
					PlayQueueService.playingEnded(id, 'Playback ended');
					cleanupPlayer(playing);
					playNext();
				}
				else {
					$log.error(`[Player] playingEnded of item ${id} for scene id ${scope.scene.id}, but this item wasn't playing! Playing id is: ${playing.id}`);
				}
			});

			// Emitted when a player encountered an error
			scope.$on('playerError', function(event, id, error) {
				if (playing && playing.id === id) {
					// Error on playing: Log, cleanup and trigger next
					$log.error(`[Player] playerError of item ${id} for scene id ${scope.scene.id} AS PLAYING. Error: ${error.message}`, error);
					cleanupPlayer(playing);
					playNext();
				}
				else {
					// Error on queue: Log, mark as failed and trigger next
					const erroredPlayer = playQueue[id];
					$log.error(`[Player] playerError of item ${id} (${erroredPlayer.scope.data.type}) for scene id ${scope.scene.id} on queue. Error: ${error.message}`, error);
					erroredPlayer.ready = false;
					PlayQueueService.playingEnded(id, `Error: ${error.message}`, error)
						.finally(() => {
							playNext();
						});
				}
			});

			/// PlayQueue change handling

			// Take care of incoming playlistItems
			function handlePlayQueueChange(err, playlistItem) {
				if (err) {
					$log.error(`[Player] handlePlayQueueChange got error for scene id ${scope.scene.id}:`, err);
					return;
				}

				// The change was the removal of a playlistItem
				if (playlistItem.removed) {
					// We only remove stuff if on the queue. We don't interrupt the playing item.
					if (playQueue[playlistItem.id]) {
						playQueue[playlistItem.id].scope.$destroy();
						delete playQueue[playlistItem.id];
						$log.warn(`[Player] handlePlayQueueChange removed unplayed for scene id ${scope.scene.id}:`, playlistItem);
					}

					return;
				}

				/// Otherwise the change was the addition of a new playlistItem

				// Parse the extra data on playlistItem
				if (typeof playlistItem.itemData === 'string') {
					try {
						playlistItem.itemData = JSON.parse(playlistItem.itemData);
					}
					catch (e) {
						$log.error(`[Player] handlePlayQueueChange got new playlistItem, but could not parse itemData for scene id ${scope.scene.id}:`, playlistItem);
						return void PlayQueueService.playingEnded(playlistItem.id, `Could not parse itemData: ${e.message}`);
					}
				}

				// Do the shuffle
				const itemData = playlistItem.itemData;
				delete playlistItem.itemData;
				Object.assign(playlistItem, itemData);

				// Build player
				const player = PlayerFactory.create(playlistItem, scope.scene.orientation, scope);
				if (player) {
					playQueue[playlistItem.id] = player;
				}
				else {
					$log.error(`[Player] handlePlayQueueChange got new playlistItem, but could not create player for scene id ${scope.scene.id}:`, playlistItem);
					return void PlayQueueService.playingEnded(playlistItem.id, `Could not build player`);
				}
			}

			// Get currently queued playlistItems
			getPlayQueueForPlayback();

			// Setup subscription for playQueue changes
			PlayQueueService.subscribe(scope.scene.id, handlePlayQueueChange)
				.catch(err => {
					$log.error(`[Player] Could not subscribe to playQueue changes for scene id ${scope.scene.id}`, err);
				});

			/// Scene status

			// Set version
			SceneService.update({
				id: scope.scene.id,
				version: JSON.stringify(ClientService.version)
			});

			// Send heartbeat and setup interval
			function sendHeartbeat() {
				SceneService.update({
					id: scope.scene.id,
					lastSeen: Math.floor(Date.now() / 1000)
				});
			}
			sendHeartbeat();
			let heartbeatInterval = $interval(sendHeartbeat, 15000);

			scope.$on('$destroy', () => {
				// Cleanup heartbeat
				if (heartbeatInterval) {
					$interval.cancel(heartbeatInterval);
					heartbeatInterval = undefined;
				}

				// Cleanup playNext
				if (playNextInterval) {
					$interval.cancel(playNextInterval);
					playNextInterval = undefined;
				}
			});
		}
	};
}
