export default function fileMediaVideoPlayerComponent($log, $timeout, PlayerUtil) {
	'ngInject';
	return {
		restrict: 'E',
		scope: false, // TODO: Refactor
		bindToController: {},
		controller: () => {},
		controllerAs: 'ctrl',
		template: '<video id="videoPlayer" ng-style="{width: dimensions.width, height: dimensions.height}" disableRemotePlayback></video>',
		link: function(scope, elem) {
			scope.dimensions = PlayerUtil.getFsDimensions(scope.data.width, scope.data.height);

			let errorFn = function(error) {
				scope.data.played = true;
				scope.$emit('playerError', scope.data.id, error);
			};

			let preloadCompleteFn = function() {
				scope.$emit('preloadComplete', scope.data.id);
			};

			let video = angular.element(elem[0].querySelector('#videoPlayer'));

			video.on('canplaythrough', preloadCompleteFn);
			video.on('error', () => errorFn(new Error(`FileMediaVideoPlayer error for: ${scope.data.url}`)));
			video.on('stalled', () => errorFn(new Error(`FileMediaVideoPlayer stalled for: ${scope.data.url}`)));
			video.on('ended', function() {
				scope.api.stop();
			});
			// cache queue pram bruges af service-worker, som signl til video til i cache.
			video.attr('src', scope.data.url + '?cache=true');
			video[0].volume = 0;

			let timer = null;
			scope.data.played = false;

			scope.api.play = function() {
				scope.data.played = false;
				const playPromise = video[0].play();
				if (playPromise !== undefined) {
					playPromise.then(() => {
						const videoDuration = video[0].duration * 1000;
						let timeoutDuration = videoDuration;
						if (!isFinite(timeoutDuration)) {
							timeoutDuration = scope.data.duration;
						}
						timeoutDuration += 500;
						timer = $timeout(function() {
							$log.warn('FileMediaVideoPlayer did not complete playing in time', {
								timeoutDuration,
								videoDuration,
								data: scope.data,
								scene: scope.$parent.scene
							});
							scope.api.stop();
						}, timeoutDuration);
					}).catch(err => {
						if (err.name === 'NotAllowedError') {
							$log.warn(`FileMediaVideoPlayer was not allowed to play: ` + err.message, { scene: scope.$parent.scene });
						}
						else {
							errorFn(new Error(`FileMediaVideoPlayer failed to play for ${scope.data.url}: ` + err.message));
						}
					});
				}
			};

			scope.api.hasPlayed = function() {
				return scope.data.played;
			};

			scope.api.stop = function() {
				scope.data.played = true;
				scope.$emit('playingEnded', scope.data.id);
			};

			scope.$on('$destroy', function() {
				$timeout.cancel(timer);
				elem.remove();
			});
		}
	};
}
