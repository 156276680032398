import templateUrl from './scene-edit.component.html';

const uuidv4Pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

class SceneEditController {
	constructor($log, $q, HistoryService, SceneService, SceneCollectionService) {
		'ngInject';

		this._$log = $log;
		this._$q = $q;
		this._HistoryService = HistoryService;
		this._SceneService = SceneService;
		this._SceneCollectionService = SceneCollectionService;
	}

	$onInit() {
		this.edit = angular.isDefined(this.id);

		this.org = {
			name: '',
			orientation: 'l',
			clientId: ''
		};
		this.scene = angular.copy(this.org);

		if (this.edit) {
			// Load scene object and collection
			this._$q.all([
				this._SceneService.get(this.id),
				this._SceneCollectionService.getSceneCollectionsForScene(this.id)
			])
				.then(([scene, collection]) => {
					this.org = scene;
					this.scene = angular.copy(this.org);

					this.sceneContent = collection;
				});
		}
	}

	validateClientId(clientId) {
		return uuidv4Pattern.test(clientId);
	}

	submit(valid) {
		if (!valid) {
			return;
		}

		// TODO: Proper validation of UUID on add/save

		if (this.edit) {
			this.save();
		}
		else {
			this.add();
		}
	}

	add() {
		this._SceneService.create(this.scene)
			.then(() => this._HistoryService.goBackOrTo('manager.settings'))
			.catch(error => this._$log.log(`SceneEdit [add] failed. Scene was ${JSON.stringify(this.scene)}. Error: ${error}`));
	}

	save() {
		this._SceneService.update(this.scene)
			.then(() => this._HistoryService.goBackOrTo('manager.settings'))
			.catch(error => this._$log.log(`SceneEdit [save] failed. Scene was ${JSON.stringify(this.scene)}. Error: ${error}`));
	}

	remove() {
		this._SceneService.delete(this.id)
			.then(() => this._HistoryService.goBackOrTo('manager.settings'))
			.catch(error => this._$log.log(`SceneEdit [delete] failed. Scene was ${JSON.stringify(this.org)}. Error: ${error}`));
	}
}

export default {
	bindings: {
		id: '<?'
	},
	controller: SceneEditController,
	templateUrl
};
