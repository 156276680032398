import angular from 'angular';

import fileMediaImagePlayer from 'components/file-media-image-player';
import fileMediaVideoPlayer from 'components/file-media-video-player';

export default angular.module('file-media-player.factory', [
	fileMediaImagePlayer,
	fileMediaVideoPlayer
])
	.factory('FileMediaPlayerFactory', () => {
		'ngInject';
		return {
			create: (data) => {
				if (data.fileType === 'image') {
					return angular.element(document.createElement('file-media-image-player'));
				}
				else if (data.fileType === 'video') {
					return angular.element(document.createElement('file-media-video-player'));
				}

				return null;
			}
		};
	})
	.name;
