import angular from 'angular';
import api, { AbstractCollectionService } from 'components/api';

class SceneCollectionItemService extends AbstractCollectionService {
	constructor($q, $log, ApiService, StateService) {
		'ngInject';

		super($q, ApiService, StateService, 'SceneCollectionItem', 'scenecollectionitem');
		this.$log = $log;
		this.copiedSceneCollectionId = null;
		ApiService.subscribeToModel('scenecollection_scenecollectionitem', {}, this.sceneCollectionSceneCollectionItemSubscriber.bind(this));
	}

	// Specifies an increment, we should update all the scenecollectionitems we have
	sceneCollectionSceneCollectionItemSubscriber(error, change) {
		if (!error && change.sceneCollectionItemId) {
			this.get(change.sceneCollectionItemId).then(sceneCollectionItem => {
				this.upsertLocalInstance(sceneCollectionItem);
			});
		}
	}

	getSceneCollectionItemsForSceneCollection(sceneCollectionId) {
		return this.ApiService.call('scenecollectionitem/getSceneCollectionItems', { sceneCollectionId });
	}

	orderSceneCollectionItemUp(sceneCollectionId, sceneCollectionItemId) {
		return this.ApiService.call('scenecollectionitem/orderSceneCollectionItemUp', { sceneCollectionId, sceneCollectionItemId });
	}

	orderSceneCollectionItemDown(sceneCollectionId, sceneCollectionItemId) {
		return this.ApiService.call('scenecollectionitem/orderSceneCollectionItemDown', { sceneCollectionId, sceneCollectionItemId });
	}

	reorderSceneCollectionItems(sceneCollectionId, sourceItemId, targetItemId) {
		return this.ApiService.call('scenecollectionitem/reorderSceneCollectionItems', { sceneCollectionId, sourceItemId, targetItemId });
	}

	setCopiedSceneCollectionId(sceneCollectionId) {
		this.$log.info('setCopiedSceneCollectionId before', this.copiedSceneCollectionId);

		this.copiedSceneCollectionId = sceneCollectionId;
		this.$log.info('setCopiedSceneCollectionId after', this.copiedSceneCollectionId);
	}

	getCopiedSceneCollectionId() {
		return this.copiedSceneCollectionId;
	}

	copyItemsToSceneCollection(sourceSceneCollectionId, targetSceneCollectionId) {
		this.$log.info('copyItemsToSceneCollection', sourceSceneCollectionId, targetSceneCollectionId);
		return this.ApiService.call('scenecollectionitem/copySceneCollectionItemsSQL', {
			sourceSceneCollectionId,
			targetSceneCollectionId
		});
	}
}

export default angular.module('scenecollectionitem.service', [
	api
]).service('SceneCollectionItemService', SceneCollectionItemService).name;
