export default class HistoryService {
	constructor($state, $transitions) {
		'ngInject';

		this._$state = $state;

		this._previous = null;
		this._lastPrevious = null;

		$transitions.onStart({}, $transition$ => {
			let fromState = $transition$.from().$$state();
			if (fromState.name === '') {
				// If this is the initial abstract router state, then we ignore it
				// NB: We can't transition to abstract states, so we might need to handle this here as well
				fromState = undefined;
			}

			if (fromState) {
				this._lastPrevious = this._previous;
				this._previous = {
					state: $transition$.from(),
					params: $transition$.params('from')
				};

				$transition$.promise
					.then(() => {
						this._lastPrevious = null;
					})
					.catch(() => {
						this._previous = this._lastPrevious;
					});
			}
		});
	}

	// Gets history object of previous state
	getPreviousStateDetails() {
		if (!!this._previous && this._previous.state.history) {
			return this._previous.state.history;
		}

		return null;
	}

	// Gets history object of provided state name
	getStateDetails(stateName) {
		if (!stateName) {
			return null;
		}

		let state = this._$state.get(stateName);
		if (!!state && state.history) {
			return state.history;
		}

		return null;
	}

	// Go back if there is a previous state, otherwise go to the provided state name
	goBackOrTo(stateName) {
		if (this._previous) {
			this._$state.go(this._previous.state, this._previous.params, { prune: true });
		}
		else {
			this._$state.go(stateName, undefined, { prune: true });
		}
	}
}
