import angular from 'angular';
import angularMoment from 'angular-moment';
import angularXeditable from 'vendorfix/angular-xeditable';

import filters from 'components/filters/filters.js';

import bulkSelect from 'components/bulk-select';
import medialabelService from 'common/medialabel.service.js';
import fileMediaService from 'common/file-media.service.js';
import playQueueService from 'common/play-queue.service.js';
import serviceAnnouncementsService from 'common/service-announcements.service.js';
import socialMediaService from 'common/social-media.service.js';
import sceneCollectionService from 'common/scenecollection.service';
import sceneCollectionItemService from 'common/scenecollectionitem.service';

import emptyInfo from 'components/empty-info';
import component from './scene-content.component';

export default angular.module('scene-content', [
	filters,
	medialabelService,
	fileMediaService,
	playQueueService,
	serviceAnnouncementsService,
	socialMediaService,
	emptyInfo,
	angularMoment,
	angularXeditable,
	sceneCollectionService,
	sceneCollectionItemService,
	bulkSelect
])
	.directive('sceneContent', component)
	.name;
