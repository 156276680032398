export default class PrimusService {
	constructor($log, $rootScope, LocalStorageService) {
		'ngInject';

		this._$rootScope = $rootScope;

		// Read session id from local storage, if any
		const mirage = LocalStorageService.get('mirage');

		// Connect Primus with sessions
		this._primus = new Primus({
			reconnect: {
				max: 60000,
				retries: Infinity
			},
			mirage
		});

		// Save session id to local storage
		this._primus.on('mirage', mirage => {
			LocalStorageService.set('mirage', mirage);
		});

		this._primus.on('open', () => {
			$log.log('[Primus] Connected');
		});

		this._primus.on('reconnect', opts => {
			$log.log('[Primus] Reconnection attempt %d started', opts.attempt);
		});

		this._primus.on('reconnect scheduled', opts => {
			$log.log('[Primus] Reconnection scheduled in %d ms', opts.scheduled);
		});

		this._primus.on('reconnect failed', err => {
			$log.warn('[Primus] Reconnect failed: %s', err.message);
		});

		this._primus.on('reconnect timeout', err => {
			$log.warn('[Primus] Reconnect timeout expired: %s', err.message);
		});

		this._primus.on('reconnected', opts => {
			$log.log('[Primus] Reconnected after %d ms', opts.duration);
		});

		this._primus.on('error', err => {
			$log.error('[Primus] Error ', err);
		});

		this._primus.on('end', () => {
			$log.warn('[Primus] End');
		});

		// Debugging help
		if (process.env.NODE_ENV === 'development') {
			window.PrimusService = {
				primus: this._primus
			};
		}
	}

	on(event, listener) {
		// Listener wrapped in angular context
		function wrappedListener() {
			this._$rootScope.$evalAsync(() => {
				listener(...arguments);
			});
		}

		// Attach
		this._primus.on(event, wrappedListener.bind(this));

		// Return de-registration function
		return function $off() {
			this._primus.removeListener(event, wrappedListener);
		};
	}

	write(data) {
		this._primus.write(data);
	}
}
