import angular from 'angular';
import angularTimeago from 'vendorfix/angular-timeago';

import playerUtil from 'common/player-util.service';

import component from './social-media-player.component';

export default angular.module('social-media-player', [
	angularTimeago,
	playerUtil
])
	.directive('socialMediaPlayer', component)
	.name;
