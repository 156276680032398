import angular from 'angular';

import fileMedia from 'components/file-media';
import mediaLabel from 'components/medialabel';
import serviceAnnouncements from 'components/service-announcements/';
import socialMedia from 'components/social-media';
import sceneContent from 'components/scene-content';
import sceneStatus from 'components/scene-status';
import schedule from 'components/schedule';
import scenePlayerLog from 'components/scene-player-log';

import component from './scene.component';

export default angular.module('scene', [
	mediaLabel,
	fileMedia,
	serviceAnnouncements,
	socialMedia,
	sceneContent,
	sceneStatus.name,
	schedule,
	scenePlayerLog.name
])
	.component('scene', component);
