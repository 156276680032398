import templateUrl from './about.component.html';
import moment from 'moment';

class AboutController {
	constructor(ClientService) {
		'ngInject';

		this.about = ClientService.about;
		this.version = ClientService.version;
		this.currentDateTime = moment().format();
	}
}

export default {
	controller: AboutController,
	templateUrl
};
