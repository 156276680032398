import angular from 'angular';

import fileMedia from 'components/file-media';
import mediaLabel from 'components/medialabel';
import serviceAnnouncements from 'components/service-announcements/';
import socialMedia from 'components/social-media';

import component from './library.component';

export default angular.module('library', [
	mediaLabel,
	fileMedia,
	serviceAnnouncements,
	socialMedia
])
	.component('library', component);
