import angular from 'angular';

import BulkApplyService from 'common/bulk-apply.service';
import SceneCollectionService from 'common/scenecollection.service';
import medialabel from 'components/medialabel';
import component from './schedule.component';

export default angular.module('schedule', [
	medialabel,
	SceneCollectionService,
	BulkApplyService
])
	.directive('schedule', component)
	.name;
