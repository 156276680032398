function getStringified(buildDetails) {
	if (buildDetails == null) {
		buildDetails = {};
	}

	let version = 'unknown';

	if (buildDetails.release || buildDetails.branch) {
		version = `${buildDetails.release || buildDetails.branch}`;
	}

	if (buildDetails.buildNumber) {
		version += `-${buildDetails.buildNumber}`;
	}

	return version;
}

module.exports = {
	getStringified
};
