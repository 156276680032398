export class AlertsService {
	constructor() {
		this.alerts = [];
	}

	add(type, msg, data) {
		let extraMsg = '';
		if (data instanceof Error) {
			extraMsg = ': ' + data.toString();
		}
		else if (data && Object.keys(data).length) {
			extraMsg = ': ' + JSON.stringify(data);
		}

		this.alerts.push({
			type: type || 'warning',
			msg: msg + extraMsg
		});
	}

	close(index) {
		this.alerts.splice(index, 1);
	}
}
