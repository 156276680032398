import angular from 'angular';

import clientService from 'common/client.service';
import sceneService from 'common/scene.service';

import playQueueService from 'common/play-queue.service.js';
import playerFactory from 'components/player-factory';

import component from './player.component';

export default angular.module('player', [
	clientService,
	sceneService,
	playerFactory,
	playQueueService
])
	.directive('player', component)
	.name;
