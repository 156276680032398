import angular from 'angular';
import raven from 'raven-js';

class RavenService {
	setUserContext(user = {}) {
		raven.setUserContext(user);
	}
}

export default angular.module('raven.service', [
])
	.service('RavenService', RavenService).name;
