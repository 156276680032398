import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import authentication from 'components/authentication';
import login from 'components/login';

import scenePreLoader from 'components/scene-pre-loader';
import player from 'components/player';
import playerPreview from 'components/player-preview';

import manager from 'components/manager';
import about from 'components/about';
import feedback from 'components/feedback';
import library from 'components/library';
import rosprover from 'components/rosprover';
import scene from 'components/scene';
import sceneEdit from 'components/scene-edit';
import serviceAnnouncementsEdit from 'components/service-announcement-edit';
import settings from 'components/settings';

export default angular.module('routes', [
	uirouter,
	authentication,
	login.name,
	scenePreLoader,
	player,
	playerPreview,
	manager.name,
	about.name,
	feedback,
	library.name,
	rosprover.name,
	scene.name,
	sceneEdit.name,
	serviceAnnouncementsEdit.name,
	settings.name
])
	.config(($stateProvider, authConfigProvider) => {
		'ngInject';

		function getSceneByIdOrName($transition$, SceneService) {
			'ngInject';

			return SceneService.getByIdOrName($transition$.params().id);
		}

		$stateProvider

			.state(authConfigProvider.settings.notAuthenticatedState, {
				url: '/login',
				component: 'login'
			})

			.state('logout', {
				url: '/logout',
				controller: function(AuthService) {
					'ngInject';

					return AuthService.logout();
				}
			})

			.state('player', {
				url: '/player/:id',
				component: 'scenePreLoader',
				resolve: {
					build: () => 'player',
					scene: getSceneByIdOrName
				},
				data: {
					requiresAuth: true
				}
			})

			.state('preview-full', {
				url: '/preview-full/:id',
				component: 'scenePreLoader',
				resolve: {
					build: () => 'player-preview',
					scene: getSceneByIdOrName
				},
				data: {
					requiresAuth: true
				}
			})

			.state('manager', {
				abstract: true,
				component: 'manager',
				redirectTo: 'manager.library',
				data: {
					requiresAuth: true
				}
			})

			.state('manager.library', {
				url: '/library',
				component: 'library',
				history: {
					title: 'Library'
				}
			})

			.state('manager.preview', {
				url: '/preview/:id',
				component: 'scenePreLoader',
				resolve: {
					build: () => 'player-preview',
					scene: getSceneByIdOrName
				},
				history: {
					title: 'Preview'
				}
			})

			.state('manager.service-announcement-new', {
				url: '/service-announcements/new',
				component: 'serviceAnnouncementEdit'
			})

			.state('manager.service-announcement-edit', {
				url: '/service-announcements/:id/edit',
				component: 'serviceAnnouncementEdit',
				resolve: {
					id: function($transition$) {
						'ngInject';

						return $transition$.params().id;
					}
				}
			})

			.state('manager.scene-new', {
				url: '/scene/new',
				component: 'sceneEdit'
			})

			.state('manager.scene-edit', {
				url: '/scene/:id/configuration',
				component: 'sceneEdit',
				resolve: {
					id: function($transition$) {
						'ngInject';

						return $transition$.params().id;
					}
				}
			})

			.state('manager.scene', {
				url: '/scene/:id',
				component: 'scene',
				resolve: {
					scene: getSceneByIdOrName
				},
				history: {
					title: 'Scene'
				}
			})

			.state('manager.rosprover', {
				url: '/rosprover',
				component: 'rosprover',
				history: {
					title: 'Rosprover'
				}
			})

			.state('manager.about', {
				url: '/about',
				component: 'about',
				history: {
					title: 'About'
				}
			})

			.state('manager.feedback', {
				url: '/feedback',
				component: 'feedback',
				history: {
					title: 'Feedback'
				}
			})

			.state('manager.settings', {
				url: '/settings',
				component: 'settings',
				history: {
					title: 'Settings'
				}
			});
	});
