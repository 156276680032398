import angular from 'angular';

import { AlertsService } from './alerts.service';
import alerts from './alerts.component';
import { logDecorator } from './log.decorator';

export default angular.module('jumbotron.alerts', [])
	.config(logDecorator)
	.service('AlertsService', AlertsService)
	.component('alerts', alerts)
	.name;
