export default class SapiService {
	constructor($log, $http, CONFIGURATION) {
		'ngInject';
		this.$http = $http;
		this._$log = $log;
		this._sapiConfig = CONFIGURATION.sapi;
	}

	getPostsForModeration() {
		const self = this;
		return this.$http.get(`${this._sapiConfig.url}/api/moderate?apikey=${this._sapiConfig.apiKey}`)
			.then(function(response) {
				// Handle the response
				return response.data;
			})
			.catch(function(error) {
				// Handle the error
				self._$log.error('Error making the request', error);
				return [];
			});
	}

	updatePostApprovalState(postId, approvalState) {
		const self = this;
		return this.$http.post(`${this._sapiConfig.url}/api/moderate?apikey=${this._sapiConfig.apiKey}`, {
			id: postId,
			approvalState: approvalState
		})
			.then(function(response) {
				// Handle the response
				self._$log.info('Post approval state updated');
			})
			.catch(function(error) {
				// Handle the error
				self._$log.error('Error updating post approval state', error);
			});
	}
}
