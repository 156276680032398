import angular from 'angular';

import sceneCollectionService from 'common/scenecollection.service';
import serviceAnnouncementsService from 'common/service-announcements.service.js';
import BulkApplyService from 'common/bulk-apply.service';

import bulkSelect from 'components/bulk-select';
import medialabel from 'components/medialabel';

import component from './service-announcements.component';

export default angular.module('service-announcements', [
	bulkSelect,
	medialabel,
	serviceAnnouncementsService,
	sceneCollectionService,
	BulkApplyService
])
	.directive('libraryServiceAnnouncements', component)
	.name;
