import angular from 'angular';

import sceneService from 'common/scene.service.js';
import history from 'components/history';

import component from './scene-edit.component';

export default angular.module('scene-edit', [
	sceneService,
	history
])
	.component('sceneEdit', component);
