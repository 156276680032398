import angular from 'angular';

import serviceAnnouncementsService from 'common/service-announcements.service.js';
import history from 'components/history';

import component from './service-announcement-edit.component';

export default angular.module('service-announcement-edit', [
	serviceAnnouncementsService,
	history
])
	.component('serviceAnnouncementEdit', component);
