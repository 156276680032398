import versionHelpers from '../../shared/version-helpers';

export function logglyConfig(LogglyLoggerProvider, CONFIGURATION) {
	'ngInject';

	// Build version string
	const version = versionHelpers.getStringified(VERSION);

	// Setup Loggly
	if (CONFIGURATION.loggly.token) {
		LogglyLoggerProvider.inputToken(CONFIGURATION.loggly.token)
			.includeUrl(true)
			.includeUserAgent(true)
			.includeTimestamp(true)
			.inputTag(`${CONFIGURATION.appName}-client,version-${version}`)
			.sendConsoleErrors(true);
	}
}
