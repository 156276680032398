import templateUrl from './scenes.component.html';

class ScenesController {
	constructor($scope, ClientService, SceneService) {
		'ngInject';

		this.clientId = ClientService.id;

		this.Scenes = SceneService.getAll();

		this.unsubscribe = SceneService.subscribe(state => {
			this.Scenes = state.Scenes;
		});

		$scope.$on('$destroy', this.unsubscribe);
	}
}

export default {
	controller: ScenesController,
	templateUrl
};
