import templateUrl from './manager.component.html';
import './manager.component.less';

class ManagerController {
	constructor($rootScope, $scope, $transitions, ClientService, SceneService) {
		'ngInject';

		this.clientId = ClientService.id;

		this.Scenes = SceneService.getAll();

		this.unsubscribe = SceneService.subscribe(state => {
			this.Scenes = state.Scenes;
		});

		this.version = ClientService.version;

		// Menu is initially closed
		this.isMenuCollapsed = true;

		$transitions.onSuccess({}, () => {
			this.isMenuCollapsed = true;
		});

		// Stats for any uploads in progress
		this.uploadStats = {};
		this._uploadStatsListener = $rootScope.$on('asset-upload-service:stats', ($event, stats) => {
			this.uploadStats = stats;
		});

		$scope.$on('$destroy', () => {
			this.unsubscribe();
			this._uploadStatsListener();
		});
	}
}

export default {
	controller: ManagerController,
	templateUrl
};
