import angular from 'angular';
import api, { AbstractCollectionService } from 'components/api';

class FileMediaService extends AbstractCollectionService {
	constructor($q, ApiService, StateService, CONFIGURATION) {
		'ngInject';

		super($q, ApiService, StateService, 'FileMedia', 'filemedia');

		this._CONFIGURATION = CONFIGURATION;
	}

	_getAssetWithProperty(id, property) {
		const item = this.getLocal(id);
		if (item && item.assets) {
			const assetWithProperty = item.assets.find(asset => asset[property]);
			return assetWithProperty;
		}

		return null;
	}

	getDuration(id) {
		const asset = this._getAssetWithProperty(id, 'duration');
		if (asset) {
			return asset.duration;
		}

		return null;
	}

	getEvent(id) {
		const assetWithType = this._getAssetWithProperty(id, 'type');
		if (!assetWithType) {
			return 'analyze';
		}

		return null;
	}

	getName(id) {
		const item = this.getLocal(id);
		return item.name;
	}

	getOrientations(id) {
		const item = this.getLocal(id);
		if (item && item.assets) {
			const landscapeAvailable = item.assets.find(asset => asset.orientation === 'l');
			const portraitAvailable = item.assets.find(asset => asset.orientation === 'p');

			if (portraitAvailable && landscapeAvailable) {
				return 'both';
			}

			if (landscapeAvailable) {
				return 'l';
			}

			if (portraitAvailable) {
				return 'p';
			}
		}

		return null;
	}

	getThumbnailUrl(id) {
		const asset = this._getAssetWithProperty(id, 'thumbnailKey');
		if (asset) {
			return `https://s3-${this._CONFIGURATION.aws.region}.amazonaws.com/${this._CONFIGURATION.aws.s3Bucket}/${asset.thumbnailKey}`;
		}

		return '/assets/canopy17.png';
	}

	getS3MediaUrl() {
		return `https://s3-${this._CONFIGURATION.aws.region}.amazonaws.com/${this._CONFIGURATION.aws.s3Bucket}`;
	}

	getType(id) {
		const asset = this._getAssetWithProperty(id, 'type');
		if (asset) {
			return asset.type;
		}

		return null;
	}
}

export default angular.module('file-media.service', [
	api
])
	.service('FileMediaService', FileMediaService)
	.name;
