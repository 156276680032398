import angular from 'angular';
import angularMoment from 'angular-moment';

export default angular.module('ljungmann.filters.duration', [
	angularMoment
])
	.filter('duration', function(moment) {
		'ngInject';
		// Converts milliseconds to human readable format
		return function(input) {
			if (input == null) {
				return '';
			}

			if (isNaN(parseFloat(input)) || !isFinite(input)) {
				return 'NaN';
			}

			let duration = moment.duration(input);
			let durationHours = duration.hours();
			let durationMinutes = duration.minutes();
			let durationSeconds = duration.seconds();

			let h = durationHours > 0 ? durationHours + ':' : '';
			let m = (h.length > 0 && durationMinutes < 10 ? '0' : '') + durationMinutes + ':';
			let s = (durationSeconds < 10 ? '0' : '') + durationSeconds;

			return h + m + s;
		};
	})
	.name;
