import angular from 'angular';

export default angular.module('ljungmann.filters.bytes', [])

	.filter('bytes', function() {
		'ngInject';
		return function(bytes) {
			if (bytes === 0) {
				return '0 B';
			}

			if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
				return '-';
			}

			const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
			let number = Math.floor(Math.log(bytes) / Math.log(1024));
			let unit = units[number];
			let precision = unit !== 'B' && unit !== 'KB' ? 1 : 0;

			return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + unit;
		};
	})
	.name;
