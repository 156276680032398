import angular from 'angular';

import UserService from 'common/user.service';
import api from 'components/api/index';
import history from 'components/history';

import component from './feedback.component';
import service from './feedback.service';

export default angular.module('feedback', [
	UserService,
	api,
	history
])
	.component('feedback', component)
	.service('FeedbackService', service)
	.name;
