import templateUrl from './bulk-select.component.html';

class BulkSelectController {
	constructor($rootScope) {
		'ngInject';

		// Internal state
		this.checked = false;

		this._unsubscribeReset = $rootScope.$on('bulk-select-reset', () => {
			if (this.checked) {
				this.checked = false;
				this.onToggle();
			}
		});
	}

	$onDestroy() {
		this._unsubscribeReset();
	}

	onClick($event) {
		// Update internal state
		this.checked = !this.checked;

		// Update external state
		this.onToggle();
	}
}

export default {
	bindings: {
		mediaTitle: '<',
		onToggle: '&'
	},
	controller: BulkSelectController,
	templateUrl
};
