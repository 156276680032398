export default function fileMediaImagePlayerComponent($timeout, PlayerUtil) {
	'ngInject';
	return {
		restrict: 'E',
		scope: false, // TODO: Refactor
		bindToController: {},
		controller: () => {},
		controllerAs: 'ctrl',
		template: '<img id="imagePlayer" ng-style="{width: dimensions.width, height: dimensions.height}">',
		link: function(scope, elem) {
			scope.dimensions = PlayerUtil.getFsDimensions(scope.data.width, scope.data.height);

			let errorFn = function(error) {
				scope.data.played = true;
				scope.$emit('playerError', scope.data.id, error);
			};

			let preloadCompleteFn = function() {
				scope.$emit('preloadComplete', scope.data.id);
			};

			let img = angular.element(elem[0].querySelector('#imagePlayer'));
			img.one('load', preloadCompleteFn);
			img.on('error', () => errorFn(new Error(`FileMediaImagePlayer error for: ${scope.data.url}`)));
			img.attr('src', scope.data.url);

			let timer = null;
			scope.data.played = false;

			scope.api.play = function() {
				scope.data.played = false;
				timer = $timeout(function() {
					scope.data.played = true;
					scope.$emit('playingEnded', scope.data.id);
				}, scope.data.duration);
			};

			scope.api.hasPlayed = function() {
				return scope.data.played;
			};

			scope.$on('$destroy', function() {
				$timeout.cancel(timer);
				elem.remove();
			});
		}
	};
}
