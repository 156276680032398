import angular from 'angular';

export default angular.module('player-util.service', [])
	.factory('PlayerUtil', function($log) {
		'ngInject';
		return {
			getFsDimensions: function(width, height) {
				// Calculate dimensions for full screen

				var dimensions = {
					width: 'auto',
					height: 'auto'
				};

				let wWidth = parseInt($(window).width(), 10);
				let wHeight = parseInt($(window).height(), 10);

				let fw = wWidth / width;
				let fh = wHeight / height;

				if (fw > fh) {
					dimensions.width = Math.floor(width * fh) + 'px';
					dimensions.height = Math.floor(height * fh) + 'px';
				}
				else {
					dimensions.width = Math.floor(width * fw) + 'px';
					dimensions.height = Math.floor(height * fw) + 'px';
				}

				// FIXME: Improve this. Hack below
				dimensions = {
					width: 'inherit',
					height: '100vh'
				};

				return dimensions;
			},

			getAssetWithOrientation: function(orientation, assetName) {
				const dotIndex = assetName.lastIndexOf('.');
				if (dotIndex === -1) {
					$log.error('No dot in filename. Cannot get asset of this: ' + assetName + ' for orientation ' + orientation);
					return '';
				}

				return `/assets/${assetName.substr(0, dotIndex)}_${orientation}.${assetName.substr(dotIndex + 1)}`;
			},

			setOrientationClass: function(orientation, elem) {
				if (orientation === 'l') {
					elem.addClass('landscape');
				}
				else if (orientation === 'p') {
					elem.addClass('portrait');
				}
				else {
					$log.warn('You got to have an orientation. Otherwise I dont know how to hold you');
				}
			}
		};
	})
	.name;
