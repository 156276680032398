import angular from 'angular';
import api, { AbstractCollectionService } from 'components/api';
import { v4 as uuidv4 } from 'uuid';

class MediaLabelService extends AbstractCollectionService {
	constructor($log, $q, ApiService, StateService) {
		'ngInject';

		super($q, ApiService, StateService, 'MediaLabel', 'medialabel');
		this._log = $log;
		this._listeners = {
			filemedias: [],
			serviceannouncements: [],
			socialmediasources: []
		};

		ApiService.subscribeToModel('medialabel_filemedia', {}, this._mediaLabelRelationListener.bind(this, 'filemedias'));
		ApiService.subscribeToModel('medialabel_serviceannouncement', {}, this._mediaLabelRelationListener.bind(this, 'serviceannouncements'));
		ApiService.subscribeToModel('medialabel_socialmediasource', {}, this._mediaLabelRelationListener.bind(this, 'socialmediasources'));
	}

	_mediaLabelRelationListener(relation, error, relationInstance) {
		if (relationInstance) {
			this._listeners[relation].forEach(listener => {
				listener.callback(relationInstance);
			});
		}
	}

	getLabels(mediaType, mediaId) {
		return this.ApiService.call('medialabel/getMediaLabels', { mediaType, mediaId });
	}

	subscribeToLabelRelationChanges(mediaType, callback) {
		if (!this._listeners[mediaType]) {
			throw new Error('Unknown media type in media label service!');
		}

		const listenerUuid = uuidv4();
		this._listeners[mediaType].push({ uuid: listenerUuid, callback });

		return listenerUuid;
	}

	unsubscribeToLabelRelationChanges(mediaType, listenerUuid) {
		if (!this._listeners[mediaType]) {
			throw new Error('Unknown media type in media label service!');
		}

		for (let i = this._listeners[mediaType].length - 1; i >= 0; i--) {
			if (this._listeners[mediaType][i].uuid === listenerUuid) {
				this._listeners[mediaType].splice(i, 1);
			}
		}
	}

	addLabel(mediaType, mediaId, labelId) {
		// Example signature:
		// mediaType=filemedias (or serviceannouncements, socialmediasources)
		// mediaId="7"
		// labelId=10
		//
		// "filemedias":[{"id":7,"name":"066249056_main","createdAt":"2023-04-18T18:11:03.000Z","updatedAt":"2023-04-18T18:11:03.000Z"}]
		const targetMedia = this.StateService.state.FileMedias.find(media => media.id === parseInt(mediaId));
		if (targetMedia) {
			const mediaobj = {
				id: targetMedia.id,
				name: targetMedia.name,
				createdAt: targetMedia.createdAt,
				updatedAt: targetMedia.updatedAt
			};

			let labelObj = this.getLocal(labelId);

			// Check if an object with the same id already exists in the targetMedia.labels array
			const existingObj = targetMedia.labels.find(obj => obj.id === labelId);

			// If there's no existing object with the same id, push the mediaobj
			if (!existingObj) {
				targetMedia.labels.push(labelObj);
			}
			// If you bind a media item to an existing label, the array already exists, e.g. filemedias[]
			// If the label is created in the same session, there is a need to create this array first, e.g. filemedias[]
			if (labelObj) {
				// Check if an object with the same id already exists in the labelObj[mediaType] array
				const existingObj = labelObj[mediaType] && labelObj[mediaType].find(obj => obj.id === mediaobj.id);

				// If there's no existing object with the same id, push the mediaobj
				if (!existingObj) {
					(labelObj[mediaType] = labelObj[mediaType] || []).push(mediaobj);
				}
			}
		}
		return this.ApiService.call('medialabel/addMediaLabel', { mediaType, mediaId, labelId });
	}

	removeLabel(mediaType, mediaId, labelId) {
		const targetLabel = this.getLocal(labelId);
		if (targetLabel && targetLabel[mediaType]) {
			// Remove the media with the matching mediaId from the label
			targetLabel[mediaType] = targetLabel[mediaType].filter(media => media.id !== mediaId);
		}
		return this.ApiService.call('medialabel/removeMediaLabel', { mediaType, mediaId, labelId });
	}
}

export default angular.module('media-label.service', [
	api
])
	.service('MediaLabelService', MediaLabelService)
	.name;
