import { Visualizer } from '@uirouter/visualizer';

export function routerDebugRun($uiRouter, $trace) {
	'ngInject';

	if (process.env.NODE_ENV === 'development' && process.env.DEBUG_UIROUTER === true) {
		// Enable state visualizer
		$uiRouter.plugin(Visualizer);

		// Enable transition tracing
		$trace.enable('TRANSITION');
	}
}
