export default function stateGoDecorator($provide) {
	'ngInject';

	$provide.decorator('$state', function theStateGoDecorator($delegate) {
		'ngInject';

		// Save original function
		$delegate.baseGo = $delegate.go;

		// Assign new 'go', right now decorating the old 'go'
		$delegate.go = function(to, params, options) {
			const currentState = this.current;

			// Extract 'prune' from options
			const newOptions = options || {};
			const prune = newOptions.prune;
			delete newOptions.prune;

			// Replace history if current state is to be pruned / has no title
			if (!angular.isDefined(newOptions.location) && (prune || (currentState.history && (currentState.history.prune || !currentState.history.title)))) {
				newOptions.location = 'replace';
			}

			this.baseGo(to, params, newOptions);
		};

		return $delegate;
	});
}
