import angular from 'angular';

import playerUtil from 'common/player-util.service';

import component from './file-media-image-player.component';

export default angular.module('file-media-image-player', [
	playerUtil
])
	.directive('fileMediaImagePlayer', component)
	.name;
