class AssetUploadDropZoneController {
	constructor($log, $rootScope, toaster, AssetUploadService, bytesFilter) {
		'ngInject';

		this._$log = $log;
		this._ToastService = toaster;
		this._AssetUploadService = AssetUploadService;
		this._bytesFilter = bytesFilter;

		this.stats = {};
		this._statsListener = $rootScope.$on('asset-upload-service:stats', ($event, stats) => {
			this.stats = stats;
		});

		this.maxFileSize = 419430400; // 400 MB
	}

	$onDestroy() {
		this._statsListener();
	}

	fileDropped($files, $file, $newFiles, $duplicateFiles, $invalidFiles) {
		// Notification of invalid files
		for (const file of $invalidFiles) {
			let error;
			switch (file.$error) {
				case 'maxSize': {
					error = `File too large (${this._bytesFilter(file.size)}). Max size is ${this._bytesFilter(file.$errorParam)}`;
					break;
				}

				case 'pattern': {
					error = `File type ${file.type} invalid. File must be image or video.`;
					break;
				}

				default: {
					error = file.$error;
				}
			}

			this._$log.warn(`File rejected: ${file.name}`, { file, error });
			this._ToastService.error(`File rejected: ${file.name}`, error);
		}

		// Initiate upload of files
		for (const file of $files) {
			this._$log.info(`Initiating upload of: ${file.name}`);
			this._AssetUploadService.uploadFile(file)
				.then(() => {
					this._$log.info(`File uploaded: ${file.name}`, { file });
					this._ToastService.success(`File uploaded: ${file.name}`);
				})
				.catch(err => {
					this._$log.warn(`Failed to upload file: ${file.name} - ${err.message}`, { file, error: err });
					this._ToastService.error(`Failed to upload file: ${file.name} `, err.message);
				});
		}
	}
}

export default AssetUploadDropZoneController;
