import moment from 'moment';

import angular from 'angular';
import api from 'components/api';

class PlayQueueService {
	constructor(ApiService) {
		'ngInject';

		this._ApiService = ApiService;
	}

	get(sceneId) {
		return this._ApiService.call('playqueue/getPlayQueue', { sceneId });
	}

	subscribe(sceneId, callback) {
		const filter = { sceneId: sceneId };

		// NB: Not an unsubscribe function!
		return this._ApiService.subscribeToModel('playlistitem', filter, callback);
	}

	playingStarted(playlistItemId, result) {
		return this._ApiService.call('playqueue/playingStarted', {
			id: playlistItemId,
			timePlayed: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
			result
		});
	}

	playingEnded(playlistItemId, result) {
		return this._ApiService.call('playqueue/playingEnded', {
			id: playlistItemId,
			playbackEnded: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
			result
		});
	}
}

export default angular.module('play-queue.service', [
	api
])
	.service('PlayQueueService', PlayQueueService)
	.name;
