import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import stateGoDecorator from './state-go.decorator';
import HistoryService from './history.service';
import backButtonComponent from './back-button.component';

/*
Add a history {
	title: <title>
}
on the states that the back-button should be able to go back to.

Basically all states should have this, unless they shouldn't be a part of the history.
*/

export default angular.module('history', [
	uirouter
])
	.config(stateGoDecorator)
	.service('HistoryService', HistoryService)
	.directive('backButton', backButtonComponent)
	.run(function(HistoryService) {
		'ngInject';

		// To ensure HistoryService is registered and running
	})
	.name;
