import angular from 'angular';
import angularMoment from 'angular-moment';
import angularStrap from 'angular-strap';

import filters from 'components/filters/filters.js';

import fileMediaService from 'common/file-media.service.js';
import SceneCollectionService from 'common/scenecollection.service';
import BulkApplyService from 'common/bulk-apply.service';

import assetUpload from 'components/asset-upload';
import bulkSelect from 'components/bulk-select';
import medialabel from 'components/medialabel';

import component from './file-media.component';

export default angular.module('file-media', [
	assetUpload,
	angularMoment,
	filters,
	fileMediaService,
	SceneCollectionService,
	angularStrap,
	BulkApplyService,
	bulkSelect,
	medialabel
])
	.directive('libraryFileMedia', component)
	.name;
