/* eslint no-console: off, no-restricted-globals: off */

import angular from 'angular';
import raven from 'raven-js';
import ravenAngular from 'raven-js/plugins/angular';

import constants from './constants';
import app from './app';

import * as packageJson from '../package';
import * as versionHelpers from '../shared/version-helpers';

const initInjector = angular.injector(['ng']);
const $http = initInjector.get('$http');

function setupRaven(configuration) {
	if (configuration.sentry.dsn) {
		raven
			.config(configuration.sentry.dsn, {
				environment: configuration.targetEnv,
				release: versionHelpers.getStringified(packageJson.jumbotron.build)
			})
			.addPlugin(ravenAngular, angular)
			.install();
	}
	else {
		console.warn('Sentry not configured');
	}
}

angular.module('bootstrap', []).run(() => {
	// Simple delay function
	const chill = ms => new Promise(resolve => setTimeout(resolve, ms));

	async function loadConfiguration() {
		const descriptionElement = angular.element(document.querySelectorAll('#appLoader > span'));
		descriptionElement.text('Loading configuration');

		// Load configuration from server with exponential backoff
		let configuration;
		let delay = 500;
		for (;;) {
			try {
				configuration = await $http.get('/config.json');
				configuration = configuration.data;
				break;
			}
			catch (e) {
				console.warn('Loading configuration failed', e);

				delay = Math.min(delay * 2, 30000);

				await chill(delay);
			}
		}

		// Configuration loaded
		descriptionElement.text('Configuration loaded');
		constants.constant('CONFIGURATION', configuration);

		setupRaven(configuration);

		// Bootstrap main application
		const bootstrapAppElement = angular.element(document.querySelectorAll('#bootstrapApp'));
		const appElement = angular.element(document.querySelectorAll('#app'));

		bootstrapAppElement.css('display', 'none');
		appElement.css('visibility', 'visible');

		angular.bootstrap(appElement, [app.name], {
			strictDi: true
		});
	}

	loadConfiguration();
});
