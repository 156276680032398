import angular from 'angular';

import bytesFilter from './bytes.js';
import durationFilter from './duration.js';
import jumbotronVersionFilter from './jumbotron-version';

export default angular.module('ljungmann.filters', [
	bytesFilter,
	durationFilter,
	jumbotronVersionFilter
])
	.name;
