const setFalsyToNull = obj => {
	const clone = JSON.parse(JSON.stringify(obj));

	Object.entries(clone).forEach(([key, val]) => {
		if (!val) {
			clone[key] = null;
		}
	});

	return clone;
};

export default class AbstractCollectionService {
	constructor($q, ApiService, StateService, collectionName, collectionEndpoint) {
		this.$q = $q;
		this.ApiService = ApiService;
		this.StateService = StateService;

		this.collectionName = collectionName;
		this.collectionStateName = `${collectionName}s`;
		this.collectionEndpoint = collectionEndpoint;

		this.localState = {};
		this.StateService.subscribeToState(state => {
			this.localState[this.collectionStateName] = state[this.collectionStateName] || [];
		});
	}

	// Basically just a forwarder to the ApiService
	subscribe(callback) {
		return this.StateService.subscribeToState(() => callback(this.localState));
	}

	subscribeToId(id, callback) {
		id = parseInt(id);
		return this.subscribe((state) => {
			const item = state[this.collectionStateName].find(item => item.id === id);
			if (item) {
				return void callback(item);
			}

			// TODO - Untested: Shouldn't we always ensure the callback is invoked?
			callback();
		});
	}

	create(data) {
		// We set any falsy data to null
		const stripped = setFalsyToNull(data);

		return this.ApiService.call(`${this.collectionEndpoint}/create${this.collectionName}`, stripped);
	}

	delete(id) {
		return this.ApiService.call(`${this.collectionEndpoint}/delete${this.collectionName}`, { id });
	}

	get(id) {
		return this.$q.when()
			.then(() => {
				id = parseInt(id);
				const item = (this.localState[this.collectionStateName] || []).find(item => item.id === id);

				if (item) {
					return item;
				}

				return this.ApiService.call(`${this.collectionEndpoint}/get${this.collectionName}`, { id });
			});
	}

	getLocal(id) {
		id = parseInt(id);
		return this.localState[this.collectionStateName].find(item => item.id === id);
	}

	getAll() {
		return this.localState[this.collectionStateName] || [];
	}

	update(data) {
		// Data must include id

		// We set any falsy data to null
		const stripped = setFalsyToNull(data);
		return this.ApiService.call(`${this.collectionEndpoint}/update${this.collectionName}`, stripped);
	}

	// Used for relation changes, propagates the changes to all listeners.
	updatePropertyLocally(id, prop, value) {
		const state = this.getAll();
		for (let i = 0; i < state.length; i++) {
			if (state[i].id === id) {
				state[i][prop] = value;
			}
		}

		const newState = {};
		newState[this.collectionStateName] = state;
		this.StateService._setState(newState);
	}

	upsertLocalInstance(instance) {
		const state = this.getAll();
		let index = this.localState[this.collectionStateName].findIndex(item => item.id === instance.id);

		if (index !== -1) {
			state[index] = Object.assign({}, state[index], instance);
		}
		else {
			state.push(instance);
		}

		const newState = {};
		newState[this.collectionStateName] = state;
		this.StateService._setState(newState);
	}
}
