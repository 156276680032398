import angular from 'angular';

import playerUtil from 'common/player-util.service';

import fileMediaPlayerFactory from './file-media-player.factory';
import serviceAnnouncementPlayer from 'components/service-announcement-player';
import socialMediaPlayer from 'components/social-media-player';

export default angular.module('player.factory', [
	playerUtil,
	fileMediaPlayerFactory,
	serviceAnnouncementPlayer,
	socialMediaPlayer
])
	.factory('PlayerFactory', ($compile, $log, $rootScope, FileMediaPlayerFactory, PlayerUtil) => {
		'ngInject';
		return {
			create: (data, orientation, orgScope) => {
				var template;
				if (data.type === 'file') {
					template = FileMediaPlayerFactory.create(data);
				}
				else if (data.type === 'social') {
					template = angular.element(document.createElement('social-media-player'));
				}
				else if (data.type === 'serviceAnnouncement') {
					template = angular.element(document.createElement('service-announcement-player'));
				}
				else {
					$log.error('PlayerFactory could not serve a template for ' + data.type);
					return false;
				}

				template.attr('id', data.id);
				PlayerUtil.setOrientationClass(orientation, template);

				let scope = $rootScope.$new(false, orgScope);
				scope.data = data;
				scope.api = {};

				return {
					id: data.id,
					api: scope.api,
					elem: $compile(template)(scope),
					scope: scope
				};
			}
		};
	})
	.name;
