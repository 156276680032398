// Player assets

// Service Announcement
import 'assets/bg_service_announcement_l.jpg';
import 'assets/bg_service_announcement_p.jpg';

// Social Media
import 'assets/bg_social_media_l.jpg';
import 'assets/bg_social_media_p.jpg';
import 'assets/instagram_verified.png';

// Manager assets
import 'assets/canopy_l.png';
