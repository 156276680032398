import templateUrl from './library-media-labels.component.html';

const randomColors = [
	'#001f3f', // Navy
	'#0074D9', // Blue
	'#7FDBFF', // Aqua
	'#39CCCC', // Teal
	'#3D9970', // Olive
	'#2ECC40', // Green
	'#01FF70', // Lime
	'#C6C013', // Acid Green (yellowish)
	'#FF851B', // Orange
	'#FF4136', // Red
	'#85144b', // Maroon
	'#F012BE', // Fuchsia
	'#B10DC9' // Purple
];

class LibraryMediaLabelsController {
	constructor($scope, BulkApplyService, MediaLabelService) {
		'ngInject';

		this._BulkApplyService = BulkApplyService;
		this._MediaLabelService = MediaLabelService;

		this.editingId = false;

		this.newLabel = this._generatePlaceholderLabel();

		this.MediaLabels = MediaLabelService.getAll();
		const unsubscribe = MediaLabelService.subscribe(state => {
			this.MediaLabels = state.MediaLabels;
		});

		$scope.$on('$destroy', unsubscribe);
	}

	_generatePlaceholderLabel() {
		return {
			name: '',
			color: randomColors[Math.floor(Math.random() * randomColors.length)]
		};
	}

	toggleEdit(labelId) {
		if (this.editingId === labelId) {
			this.editingId = false;
		}
		else {
			this.editingId = labelId;
		}
	}

	saveLabel() {
		const mediaLabel = this.MediaLabels.find(mediaLabel => mediaLabel.id === this.editingId);
		this._MediaLabelService.update(mediaLabel)
			.then(() => {
				this.toggleEdit(this.editingId);
			});
	}

	createLabel() {
		this._MediaLabelService.create(this.newLabel)
			.then(() => this.resetNewLabel());
	}

	resetNewLabel() {
		this.newLabel = this._generatePlaceholderLabel();
	}

	deleteLabel(labelId) {
		if (confirm('This action deletes the label, are you sure?')) {
			this._MediaLabelService.delete(labelId);
		}
	}

	applyLabel(labelId) {
		const elementsToLabel = [];

		['serviceannouncements', 'socialmediasources', 'filemedias']
			.forEach(namespace => {
				this._BulkApplyService
					.getSelectedElements(namespace)
					.forEach(mediaId => elementsToLabel.push({ namespace, mediaId, labelId }));
			});

		// Elements have been selected, add labels
		if (elementsToLabel.length > 0) {
			elementsToLabel.forEach(element =>
				this._MediaLabelService.addLabel(element.namespace, element.mediaId, element.labelId));
		}

		// Reset all bulk-select
		this._BulkApplyService.bulkSelectReset();
	}
}

export default {
	controller: LibraryMediaLabelsController,
	templateUrl
};
