import angular from 'angular';
import api, { AbstractCollectionService } from 'components/api';

class SocialMediaService extends AbstractCollectionService {
	constructor($q, ApiService, StateService) {
		'ngInject';

		super($q, ApiService, StateService, 'SocialMediaSource', 'socialmediasource');
	}
}

export default angular.module('social-media.service', [
	api
])
	.service('SocialMediaService', SocialMediaService)
	.name;
