export default function authConfig() {
	this.settings = {
		notAuthenticatedState: false,
		authenticatedInitialState: false
	};

	this.configure = function(additionalSettings) {
		Object.assign(this.settings, additionalSettings);

		return this;
	};

	this.$get = function($log) {
		'ngInject';

		if (!this.settings.notAuthenticatedState) {
			$log.error('[authConfig] notAuthenticatedState not configured in the provider.');
		}

		if (!this.settings.authenticatedInitialState) {
			$log.error('[authConfig] authenticatedInitialState not configured in the provider.');
		}

		return Object.assign({}, this.settings);
	};
}
