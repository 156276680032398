import angular from 'angular';
import ngFileUpload from 'ng-file-upload';

import api from 'components/api';

import AssetUploadService from './asset-upload.service';
import assetUploadDropZone from './asset-upload-drop-zone.component';

export default angular.module('jumbotron.asset-upload', [
	ngFileUpload,
	api
])
	.service('AssetUploadService', AssetUploadService)
	.component('assetUploadDropZone', assetUploadDropZone)
	.name;
