import angular from 'angular';

import localStorageService from 'common/local-storage.service';

import AbstractCollectionService from './abstract-collection.service';
import ApiService from './api.service';
import PrimusService from './primus.service';
import StateService from './state.service';

export { AbstractCollectionService };

export default angular.module('jumbotron.api', [localStorageService])
	.service('PrimusService', PrimusService)
	.service('ApiService', ApiService)
	.service('StateService', StateService)
	.name;
