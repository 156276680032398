import templateUrl from './feedback.component.html';

const feedbackExplanation = `
Do you have an idea of what can be improved?

Tell us what you think works, and especially what doesn't.

Every idea is a good idea!
`.trim();

const errorExplanation = `
Help us, help you!

* What are you trying to do?
* What error are you experiencing?
* What result did you expect instead?

Can the problem be reproduced? If so, please include the steps to reproduce it as detailed as possible. That will greatly help us resolve the problem.
`.trim();

class FeedbackController {
	constructor(toaster, FeedbackService, HistoryService, UserService) {
		'ngInject';

		this._ToastService = toaster;
		this._FeedbackService = FeedbackService;
		this._HistoryService = HistoryService;

		const user = UserService.getUser();
		this.userName = user.name;
		this.userEmail = user.email;

		this.types = [{
			title: 'Give general feedback',
			subjectPrefix: '[Feedback]',
			explanation: feedbackExplanation
		}, {
			title: 'Report an error',
			subjectPrefix: '[Error reported]',
			explanation: errorExplanation
		}];

		this.working = false;
	}

	submit(valid) {
		if (!valid || valid) {
			// Always return. The feature is disabled in the UI.
			return;
		}

		const subject = `${this.selectedType.subjectPrefix} ${this.feedback.summary}`;

		this.working = true;
		this._FeedbackService
			.sendFeedback(this.userName, this.userEmail, subject, this.feedback.message)
			.then(() => {
				this._ToastService.success(`Feedback sent!`);
				this._HistoryService.goBackOrTo('manager.library');
			})
			.catch(err => {
				this._ToastService.error(`Failed to send feedback`, err.message);
			})
			.finally(() => {
				this.working = false;
			});
	}
}

export default {
	controller: FeedbackController,
	templateUrl
};
