import angular from 'angular';
import api, { AbstractCollectionService } from 'components/api';

class ServiceAnnouncementsService extends AbstractCollectionService {
	constructor($q, ApiService, StateService) {
		'ngInject';

		super($q, ApiService, StateService, 'ServiceAnnouncement', 'serviceannouncement');
	}

	getName(id) {
		const item = super.getLocal(id);
		if (item) {
			return item.name;
		}

		return null;
	}
}

export default angular.module('service-announcements.service', [
	api
])
	.service('ServiceAnnouncementsService', ServiceAnnouncementsService)
	.name;
