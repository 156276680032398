import templateUrl from './login.component.html';

class LoginController {
	constructor($log, $timeout, AuthService) {
		'ngInject';

		this._$log = $log;
		this._$timeout = $timeout;
		this._AuthService = AuthService;
	}

	$onInit() {
		this.user = {
			email: '',
			password: ''
		};

		this.working = false;
		this.error = '';
	}

	submit(valid) {
		if (!valid) {
			return;
		}

		this._$log.log('loginController [submit]: Login!');

		this.working = true;
		this.error = '';

		this._AuthService.login(this.user.email, this.user.password)
			.catch(error => {
				this._$log.error('loginController: [submit]: Error', error);
				this.error = `${error.ErrorId ? `[${error.ErrorId}] ` : ''} ${error.message}`;
			})
			.finally(() => {
				this._$timeout(() => {
					this.working = false;
				}, 2000);
			});
	}
}

export default {
	controller: LoginController,
	templateUrl
};
