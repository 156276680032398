import templateUrl from './social-media.tpl.html';

export default function socialMediaComponent(BulkApplyService, SocialMediaService, SceneCollectionService) {
	'ngInject';
	return {
		restrict: 'E',
		scope: {},
		bindToController: {
			scene: '=?'
		},
		controller: () => {},
		controllerAs: 'ctrl',
		templateUrl: templateUrl,
		link: function($scope, $element, $attrs, ctrl) {
			ctrl.SocialMediaSources = SocialMediaService.getAll();

			ctrl.unsubscribe = SocialMediaService.subscribe(state => {
				ctrl.SocialMediaSources = state.SocialMediaSources;
			});

			$scope.$on('$destroy', () => {
				ctrl.unsubscribe();
				BulkApplyService.destroyNamespace('socialmediasources');
			});

			ctrl.addToScene = function addSocialToScene(id) {
				SceneCollectionService.addSceneCollectionItemToSceneCollection('social', id);
			};

			ctrl.toggleBulk = function toggleBulkOnSocialMedia(id) {
				BulkApplyService.toggleElement('socialmediasources', id);
			};
		}
	};
}
