import templateUrl from './media-labels.component.html';

class MediaLabelsController {
	constructor($scope, MediaLabelService, $log) {
		'ngInject';

		this._$scope = $scope;
		this._log = $log;

		this._MediaLabelService = MediaLabelService;

		this.namespace = '';
		this.idAttribute = '';
		this.labelOwnerId = 0;

		this.MediaLabels = [];
	}

	$onInit() {
		if (this.serviceAnnouncementId) {
			this.namespace = 'serviceannouncements';
			this.idAttribute = 'serviceannouncementId';
			this.labelOwnerId = this.serviceAnnouncementId;
		}
		else if (this.socialMediaId) {
			this.namespace = 'socialmediasources';
			this.idAttribute = 'socialmediasourceId';
			this.labelOwnerId = this.socialMediaId;
		}
		else if (this.filemediaId) {
			this.namespace = 'filemedias';
			this.idAttribute = 'filemediaId';
			this.labelOwnerId = this.filemediaId;
		}
		else {
			throw new Error('Unknown label type');
		}

		// Setup subscription of MediaLabels (invoked on subscribe)
		this._unsubscribe = this._MediaLabelService.subscribe(state => {
			// We have a subscription for every media, serviceannouncements, socialmediasources items.
			// AND will be called at init, when new/deleted label, and ad-hoc (pr media item)
			// state returns date like this below - array with all labels:

			// {"MediaLabels":[{"id":1,"name":"Lørdag aften","color":"#7FDBFF","createdAt":"2023-03-18T15:03:03.000Z","updatedAt":"2023-04-18T17:55:02.000Z","filemedias":[{"id":5,"name":"jumbotron5sec1","createdAt":"2023-03-18T15:45:14.000Z","updatedAt":"2023-03-18T15:45:14.000Z"}],"serviceannouncements":[],"socialmediasources":[{"id":2,"type":"InstagramHashtag","filter":"rf50","lastUpdate":"2023-04-30T06:51:42.000Z","createdAt":"2023-03-18T15:00:09.000Z","updatedAt":"2023-04-30T08:56:54.000Z"}]},
			//
			// Find the MediaLabels for this Media
			// Returns a array of: { id: mediaLabel.id, name: mediaLabel.name, color: mediaLabel.color } into this.MediaLabels
			this.MediaLabels = state.MediaLabels.filter(mediaLabel =>
				mediaLabel.hasOwnProperty(this.namespace) &&
				mediaLabel[this.namespace].some(item => item.id === this.labelOwnerId)
			).map(mediaLabel => ({ id: mediaLabel.id, name: mediaLabel.name, color: mediaLabel.color }));
		});
		// this.getMediaLabels();
		// Setup subscription of MediaLabel relation changes
		this._relationChangeUuid = this._MediaLabelService
			.subscribeToLabelRelationChanges(this.namespace, relationInstance => {
				if (relationInstance[this.idAttribute] === this.labelOwnerId) {
					this.getMediaLabels();
				}
			});
	}

	$onDestroy() {
		this._unsubscribe();
		this._MediaLabelService.unsubscribeToLabelRelationChanges(this.namespace, this._relationChangeUuid);
	}

	getMediaLabels() {
		// Get MediaLabels for this Media instance
		return this._MediaLabelService
			.getLabels(this.namespace, this.labelOwnerId)
			.then(MediaLabels => {
				this.MediaLabels = MediaLabels;
			});
	}

	removeLabel(labelId) {
		// Remove label from media
		return this._MediaLabelService
			.removeLabel(this.namespace, this.labelOwnerId, labelId);
	}
}

export default {
	bindings: {
		filemediaId: '<',
		serviceAnnouncementId: '<',
		socialMediaId: '<'
	},
	controller: MediaLabelsController,
	templateUrl
};
