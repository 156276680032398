import angular from 'angular';

import api from 'components/api';

class BulkApplyService {
	constructor($rootScope) {
		'ngInject';

		this._$rootScope = $rootScope;

		this.selectedElements = {};
	}

	selectElement(namespace, id) {
		if (!this.selectedElements[namespace]) {
			this.selectedElements[namespace] = {};
		}

		this.selectedElements[namespace][id] = id;
	}

	unselectElement(namespace, id) {
		delete this.selectedElements[namespace][id];
	}

	toggleElement(namespace, id) {
		if (this.selectedElements[namespace] && this.selectedElements[namespace][id]) {
			this.unselectElement(namespace, id);
		}
		else {
			this.selectElement(namespace, id);
		}
	}

	getSelectedElements(namespace) {
		return Object.keys(this.selectedElements[namespace] || {});
	}

	destroyNamespace(namespace) {
		this.selectedElements[namespace] = {};
	}

	bulkSelectReset() {
		this._$rootScope.$emit('bulk-select-reset');
	}
}

export default angular.module('bulk-apply.service', [
	api
]).service('BulkApplyService', BulkApplyService).name;
