import angular from 'angular';

import playerUtil from 'common/player-util.service';

import component from './file-media-video-player.component';

export default angular.module('file-media-video-player', [
	playerUtil
])
	.directive('fileMediaVideoPlayer', component)
	.name;
