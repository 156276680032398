import angular from 'angular';

import authentication from 'components/authentication';

import component from './login.component';

export default angular.module('login', [
	authentication
])
	.component('login', component);
