import { RejectType } from '@uirouter/angularjs';

export function routerRun($log, $transitions) {
	'ngInject';

	// Print error and stack on state change error
	$transitions.onError({}, transition => {
		const error = transition.error();
		if (error.type === RejectType.IGNORED) {
			$log.info(`[Router Run] ${error.message} - The transition is targeting the same state`);
			return;
		}
		$log.error(`[Router Run] ${error.message}`, error);
	});
}
