export default class StateService {
	constructor($log, ApiService) {
		'ngInject';

		this._$log = $log;
		this._ApiService = ApiService;

		this._state = {};

		this._stateListeners = [];

		this._notifyDelay = 1500;
		this._notificationTimeout = null;
		this._lastNotificationTime = 0;

		// Debugging help
		if (process.env.NODE_ENV === 'development') {
			window.StateService = this;
		}
	}

	_setState(newState) {
		// Update state
		this._state = Object.assign({}, this._state, newState);

		const timeSinceLastNotification = Date.now() - this._lastNotificationTime;
		if (timeSinceLastNotification > this._notifyDelay) {
			this._notifyListeners();
		}
		else {
			this._debounceNotification();
		}

		// Return the updated state
		return this._state;
	}

	_notifyListeners() {
		this._lastNotificationTime = Date.now(); // Update the time of the last notification
		this._stateListeners.forEach(listener => listener(this._state));
	}

	_debounceNotification() {
		if (this._notificationTimeout) {
			clearTimeout(this._notificationTimeout);
		}

		this._notificationTimeout = setTimeout(() => {
			this._notifyListeners();
			this._notificationTimeout = null;
		}, this._notifyDelay);
	}

	get state() {
		return Object.assign({}, this._state);
	}

	subscribeToState(listener) {
		// Save listener
		this._stateListeners.push(listener);

		// Invoke with current state
		listener(this.state);

		// Return an unsubscribe function
		return () => {
			const index = this._stateListeners.indexOf(listener);
			this._stateListeners.splice(index, 1);
		};
	}

	_authenticatedSubscribeToFullState() {
		// Get fresh data from the server
		this._ApiService.call('server/getFullState')
			.then(serverState => {
				// Set the state
				return this._setState(serverState);
			}).then(newState => {
				Object.keys(newState).forEach(model => {
					// Identify singular model name
					let trimmed = model;
					if (trimmed.slice(-1) === 's') {
						trimmed = trimmed.substring(0, trimmed.length - 1);
					}

					// Subscribe to model changes
					this._ApiService.subscribeToModel(trimmed, [], (err, update) => {
						if (err) {
							this._$log.error(`[State] Got error for model change of ${trimmed}`, err);
							return;
						}

						const oldState = Object.assign({}, this.state);
						const updated = {};
						if (update && update.new) {
							delete update.new;
							oldState[model].push(update);
							updated[model] = oldState[model];
						}
						else if (update && update.removed) {
							updated[model] = oldState[model].filter(elem => {
								return update.id !== elem.id;
							});
						}
						else if (update) {
							updated[model] = oldState[model].map(elem => {
								if (update.id === elem.id) {
									return update;
								}

								return elem;
							});
						}

						this._setState(updated);
					});
				});
			});
	}
}
