import templateUrl from './empty-info.component.html';

export default function emptyInfoComponent() {
	'ngInject';
	return {
		restrict: 'E',
		scope: {},
		bindToController: {
			no: '@'
		},
		controller: () => {},
		controllerAs: 'ctrl',
		templateUrl
	};
}
