import angular from 'angular';
import api, { AbstractCollectionService } from 'components/api';
import moment from 'moment';

function generateEmptySchedule() {
	return {
		id: null,
		name: null,
		intervals: [],
		priority: null
	};
}

class SceneCollectionService extends AbstractCollectionService {
	constructor($q, $log, ApiService, StateService, toaster) {
		'ngInject';

		super($q, ApiService, StateService, 'SceneCollection', 'scenecollection');

		this._ToastService = toaster;
		this.$q = $q;
		this.$log = $log;
		this.activeSceneCollectionId = null;
		this.scheduleFormModel = generateEmptySchedule();
		this.scheduleFormModelSubscriber = () => {};
		this.upsertSchedule = this.upsertSchedule.bind(this);
		this.subscribeToFormModel = this.subscribeToFormModel.bind(this);
		this.appendInterval = this.appendInterval.bind(this);
		this.removeInterval = this.removeInterval.bind(this);
		this.resetScheduleFormModel = this.resetScheduleFormModel.bind(this);
		ApiService.subscribeToModel('scenecollection_scene', {}, this.sceneCollectionSceneSubscriber.bind(this));
	}

	sceneCollectionSceneSubscriber(error, change) {
		if (!error && change.sceneCollectionId && change.sceneId) {
			this.updatePropertyLocally(change.sceneCollectionId, 'updatedAt', change.updatedAt);
		}
	}

	subscribeToFormModel(cb) {
		this.scheduleFormModelSubscriber = cb;
		return () => { // Return unsubscribe function
			this.scheduleFormModelSubscriber = () => {};
		};
	}

	appendInterval() {
		this.scheduleFormModel.intervals.push({
			priority: null,
			time_interval_start: null,
			time_interval_end: null,
			date_iterval_start: null,
			date_iterval_end: null
		});
		this.scheduleFormModelSubscriber();
	}

	removeInterval(index, interval) {
		if (interval.id) {
			this.ApiService.call('interval/deleteInterval', { id: interval.id });
		}

		this.scheduleFormModel.intervals.splice(index, 1);
		this.scheduleFormModelSubscriber();
	}

	resetScheduleFormModel() {
		this.scheduleFormModel = generateEmptySchedule();
		this.scheduleFormModelSubscriber();
	}

	editSchedule(sc) {
		this.resetScheduleFormModel();
		this.scheduleFormModel.id = sc.id;
		this.scheduleFormModel.name = sc.name;
		this.scheduleFormModel.priority = sc.priority;
		this.scheduleFormModel.intervals = sc.intervals.map(interval => {
			const startMoment = moment(interval.start_datetime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			const endMoment = moment(interval.end_datetime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

			return {
				id: interval.id,
				priority: interval.priority,
				time_interval_start: new Date(interval.start_datetime),
				time_interval_end: new Date(interval.end_datetime),
				date_iterval_start: startMoment.format(),
				date_iterval_end: endMoment.format()
			};
		});

		this.scheduleFormModelSubscriber();
	}

	upsertSchedule(sceneId) {
		const sc = this.scheduleFormModel;
		sc.intervals = sc.intervals.map(interval => {
			const startTime = moment(interval.time_interval_start);
			const intervalStart = moment(interval.date_iterval_start).set({
				hour: startTime.get('hour'),
				minute: startTime.get('minute')
			});

			const endTime = moment(interval.time_interval_end);
			const intervalEnd = moment(interval.date_iterval_end).set({
				hour: endTime.get('hour'),
				minute: endTime.get('minute')
			});

			return {
				id: interval.id,
				interval_start: intervalStart.format(),
				interval_end: intervalEnd.format(),
				priority: parseInt(interval.priority, 10)
			};
		});

		let upsertPromise = this.$q.resolve();
		if (sc.id) {
			upsertPromise.then(() => this.updateSchedule(sceneId, sc));
		}
		else {
			upsertPromise.then(() => this.createSchedule(sceneId, sc));
		}

		return upsertPromise.then(() => this.resetScheduleFormModel());
	}

	updateSchedule(sceneId, sc) {
		return this.update({
			id: sc.id,
			name: sc.name,
			priority: sc.priority
		}).then(() => this.$q.all(sc.intervals.map(interval => {
			interval.sceneCollectionId = sc.id;
			return this.addInterval(interval);
		})));
	}

	createSchedule(sceneId, sc) {
		return this.create({
			name: sc.name,
			priority: sc.priority
		}).then(sceneCollectionInstance => {
			sc.id = sceneCollectionInstance.id;
			return this.$q.all(sc.intervals.map(interval => {
				interval.sceneCollectionId = sceneCollectionInstance.id;
				return this.addInterval(interval);
			}));
		}).then(() => {
			this.addSceneCollectionToScene(sceneId, sc.id);
		});
	}

	getSceneCollectionsForScene(sceneId) {
		return this.ApiService.call('scenecollection/getSceneCollections', { sceneId });
	}

	getActiveSceneCollectionId(sceneId) {
		return this.ApiService.call('scene/getActiveSceneCollectionId', { sceneId });
	}

	addInterval(interval) {
		return this.ApiService.call('scenecollection/addInterval', {
			id: interval.id,
			sceneCollectionId: interval.sceneCollectionId,
			interval_start: interval.interval_start,
			interval_end: interval.interval_end,
			priority: interval.priority
		});
	}

	playNow(sceneCollectionId) {
		return this.ApiService.call('scenecollection/playNow', { sceneCollectionId });
	}

	addSceneCollectionToScene(sceneId, sceneCollectionId) {
		return this.ApiService.call('scenecollection/addSceneCollectionToScene', { sceneId, sceneCollectionId });
	}

	addSceneCollectionItemToSceneCollection(mediaType, mediaId) {
		if (!this.activeSceneCollectionId) {
			this._ToastService.error('No playlist selected! Please select one by clicking the name on an item in scene content.');
			return this.$q.reject(new Error('No playlist selected! Please select one.'));
		}

		return this.ApiService.call('scenecollectionitem/addSceneCollectionItemToSceneCollection', {
			sceneCollectionId: this.activeSceneCollectionId,
			mediaType,
			mediaId
		});
	}

	setActiveSceneCollection(sceneCollectionId) {
		this.activeSceneCollectionId = sceneCollectionId;
	}

	removeActiveSceneCollection() {
		this.activeSceneCollectionId = null;
	}
}

export default angular.module('scenecollection.service', [
	api
])
	.service('SceneCollectionService', SceneCollectionService).name;
