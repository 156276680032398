export function angularTimeagoConfig(timeAgoSettings) {
	'ngInject';

	timeAgoSettings.overrideLang = 'rf';
	timeAgoSettings.strings.rf = {
		prefixAgo: null,
		prefixFromNow: null,
		suffixAgo: null,
		suffixFromNow: 'from now',
		seconds: '1 m.',
		minute: '1 m.',
		minutes: '%d m.',
		hour: '1 h.',
		hours: '%d h.',
		day: '1 d.',
		days: '%d d.',
		month: 'about a month',
		months: '%d months',
		year: 'about a year',
		years: '%d years',
		numbers: []
	};
}
